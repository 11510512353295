import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import React, { Fragment, useEffect, useState } from "react";
import { DialogContent, Dialog, Grid } from "@mui/material";

import CreativeForm from "./CreativeForm";
import { creative } from "../campaignObject";
import { useAxios, useComponent } from "../../../../hooks";
import {
  PrimaryButton,
  CancelButton,
  PrimaryLoadingButton,
} from "../../../css components/Button";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function CreativeDialog({
  editState,
  setEditState,
  detail,
  setDetail,
  formikProps,
  component,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();
  const [adSize, setAdSize] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleClose = () => {
    setDetail({ state: false, data: {}, id: "" });
  };

  useEffect(() => {
    axios({
      url: "/adserver/listAdTypeForChannel",
    })
      .then((response) => {
        if (response.success) {
          setAdSize(response.adTypes.items);
        }
      })
      .catch((error) => {});
  }, [axios]);

  return (
    <Card
      open={detail.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <Formik
        enableReinitialize
        initialValues={detail.data}
        validationSchema={creativeSchema}
        validateOnChange
        onSubmit={(formData, { setSubmitting }) => {
          setSubmitLoading(true);
          let url = "/user/brand/campaign/creatives/";
          let method = "POST";
          if (!!detail.id) {
            url =
              "/user/brand/campaign/creatives?creativeId=" +
              formData.creativeID;
            method = "PUT";
          }

          axios({
            url: url,
            method: method,
            disableRedirect: true,
            data: creative(
              formData,
              detail.campaignDetails.campaignID,
              detail.data
            ),
          }).then((response) => {
            if (response.success) {
              if (!!detail.id) {
                dispatch(alert.success("Creative updated Successfully!"));

                formikProps.setValues((prev) => ({
                  ...prev,
                  creatives: setCreative(prev.creatives),
                }));

                function setCreative(creative) {
                  for (let create of creative) {
                    if (create.creativeID === formData.creativeID) {
                      create.fileUrl = response.data.url;
                      create.creative = response.data.file_path;
                      create.fileName = response.data.file_path;
                      create.creativeID = response.data.creative_id;
                      create.format = response.data.banner_format;
                      create.friendlyName = response.data.creative_name;
                      create.adSize = response.data.banner_size;
                      create.adTag = response.data.ad_tag || "";
                    }
                  }
                  return creative;
                }
              } else {
                dispatch(alert.success("Creative Created Successfully!"));

                formikProps.setValues((prev) => ({
                  ...prev,
                  creatives: [
                    ...prev.creatives,
                    {
                      creativeID: response.data.creative_id,
                      format: response.data.banner_format,
                      creative: response.data.file_path,
                      fileUrl: response.data.url,
                      friendlyName: response.data.creative_name,
                      fileName: response.data.file_path,
                      adSize: response.data.banner_size,
                      adTag: response.data.ad_tag || "",
                    },
                  ],
                }));
              }

              handleClose();
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
            }
          });
          setSubmitting(false);
        }}
      >
        {(dialogFormikProps) => (
          <DialogContent>
            <Grid container spacing={3} style={{ padding: "20px" }}>
              <CreativeForm
                formikProps={formikProps}
                dialogFormikProps={dialogFormikProps}
                editState={editState}
                setEditState={setEditState}
                handleClose={handleClose}
                component={component}
                adSize={adSize}
              />

              {component !== "superadmin" && component !== "request" && (
                <Fragment>
                  {editState ? (
                    <Grid item>
                      <PrimaryLoadingButton
                        variant="contained"
                        type="submit"
                        disabled={!dialogFormikProps.isValid}
                        loading={submitLoading}
                        onClick={dialogFormikProps.handleSubmit}
                      >
                        Save
                      </PrimaryLoadingButton>
                    </Grid>
                  ) : (
                    <Grid item>
                      <PrimaryButton
                        variant="contained"
                        onClick={() => setEditState(!editState)}
                      >
                        Edit Creative
                      </PrimaryButton>
                    </Grid>
                  )}
                  <Grid item>
                    <CancelButton variant="outlined" onClick={handleClose}>
                      Cancel
                    </CancelButton>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </DialogContent>
        )}
      </Formik>
    </Card>
  );
}

const creativeSchema = Yup.object().shape({
  format: Yup.string().required("Please select format"),
  friendlyName: Yup.string().required("Give creative a name"),
  adSize: Yup.string().when("format", {
    is: (val) => val !== "ad-tag",
    then: Yup.string().required("Fill the field"),
  }),
  adTag: Yup.string().when("format", {
    is: (val) => val === "ad-tag",
    then: Yup.string().required("Fill the field"),
  }),
});
