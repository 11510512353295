import styled from "styled-components";
import Divider from "@mui/material/Divider";
import { Grid, Typography } from "@mui/material";

import { Navbar } from "./Navbar";
import Form from "./log in components/Form";

const OuterGrid = styled(Grid)`
  padding-left: 13.19%;
  padding-right: 13.19%;
  margin-top: 10px;
`;

const WelcomeTypography = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: rgba(0, 0, 0, 1);
`;

const BrandTypography = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: rgba(189, 189, 189, 1);
`;

export default function LogIn() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Navbar />
      </Grid>

      <Grid item xs={12} style={{ padding: "1px 50px", marginTop: "45px" }}>
        <Divider />
      </Grid>

      <OuterGrid container spacing={2}>
        <Grid item xs={4}>
          <WelcomeTypography>Welcome to</WelcomeTypography>

          <BrandTypography>MOX Platform</BrandTypography>
        </Grid>

        <Grid item xs={8}>
          <Form />
        </Grid>
      </OuterGrid>
    </Grid>
  );
}
