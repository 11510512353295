import { Fragment } from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import Brand from "../../../assets/BrandLogo.png";
import { Paper, TextField, Label } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const FontColor = styled(Typography)`
  font-size: 48px;
  color: #34b53a;
  font-weight: 600;
`;

const ImageDiv = styled.div`
  width: 70px;
  height: 70px;
`;

export default function Form() {
  return (
    <Paper>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <MainHeading>Campaign Details</MainHeading>
        <FontColor component="div" alignSelf={"center"} display="flex">
          <svg
            style={{ position: "relative", margin: "9px 9px" }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#5AC35F" />
          </svg>
          <Typography
            style={{
              fontSize: "21px",
              fontWeight: "400",
            }}
          >
            In Progress
          </Typography>
        </FontColor>
      </Grid>

      <Fragment>
        <Grid
          item
          xs={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <ImageDiv>
            {" "}
            <img
              style={{
                height: "inherit",
                width: "inherit",
                borderRadius: "6px",
              }}
              src={Brand}
              alt="!"
            />
          </ImageDiv>
          <Typography
            component="span"
            style={{
              marginLeft: "8px",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            Flickstree
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Label title="Promotion Partner" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="Promotion Partner"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <Label title="Campaign Name" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="Campaign Name"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title="Campaign Destination URL" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="Campaign Destination URL"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Campaign Duration"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            name="destination_url.value"
            placeholder="Campaign Duration"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>
      </Fragment>
    </Paper>
  );
}
