import { Grid } from "@mui/material";

import { Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function CampaignStatus({ formikProps, setTakeUpOffer }) {
  return (
    <Paper>
      {formikProps.values.campaignStatus === "REJECTED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign Offer was rejected.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.success}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}

      {formikProps.values.campaignStatus === "PENDING" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.warning}>
            Campaign is in pending state, Take action.
          </ActiveTextEighteen>
        </Grid>
      )}

      {formikProps.values.campaignStatus === "ACCEPTED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.success}>
            Campaign Offer was accepted.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.error}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "REJECTED",
                  state: true,
                }))
              }
            >
              Reject Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}

      {formikProps.values.campaignStatus === "PROOF_GENERATION_FAILED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign proof generation failed.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Accept Offer again. &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.success}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}
    </Paper>
  );
}
