import React from "react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import Form from "./Form";
import ReportFormOne from "./ReportFormOne";
import pages from "../../../constants/pages";
import CampaignMetrics from "./CampaignMetrics";
import { PageHeader } from "../../../components";

const GenerateReport = styled(Button)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 20px;
`;

export default function GenrateReport() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"My Billings"} />
        </Grid>
        <Grid item xs />

        <Grid item alignSelf="center">
          <GenerateReport
            variant="contained"
            size="large"
            onClick={() => navigate(pages.billingReport.route)}
          >
            Generate Report
          </GenerateReport>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Form />
          <CampaignMetrics />
        </Grid>
        <Grid item xs={6}>
          <ReportFormOne />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
