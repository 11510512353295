import { Fragment } from "react";
import { Button } from "@mui/material";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Paper, TextField, Label } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Payout = styled(Button)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 20px;
`;

const Halt = styled(Button)`
  background: #ff3a29;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 20px;
  margin-left: 15px;
`;

const FontColor = styled(Typography)`
  font-size: 48px;
  color: #34b53a;
  font-weight: 600;
`;

export default function ReportFormOne() {
  return (
    <Paper>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <MainHeading>Payout</MainHeading>
      </Grid>

      <Fragment>
        <Grid item xs={6}>
          <Label title="Total Available for Payout" />
          <TextField
            style={{ backgroundColor: "#34B53A", color: "white" }}
            placeholder="$2,800,000"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={6}>
          <Label title="Expected Today" />
          <TextField
            style={{ backgroundColor: "#34B53A", color: "white" }}
            placeholder="$2,800,000"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={6}>
          <Label title="Outstanding (Last 60 days)" />
          <TextField
            style={{ backgroundColor: "#34B53A", color: "white" }}
            placeholder="$2,800,000"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <Label title="Conversion Type" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="Revenue Share"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title="Payout Percentage" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="30% of Sales Revenue (Net of Tax)"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Payout Terms"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            name="destination_url.value"
            placeholder="60 days from campaign end date"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={10} style={{ display: "flex", marginTop: "10px" }}>
          <Grid item alignSelf="center">
            <Payout variant="contained" size="large">
              Payout
            </Payout>
          </Grid>
          <Grid item alignSelf="center">
            <Halt variant="contained" size="large">
              Halt Offer
            </Halt>
          </Grid>
        </Grid>
      </Fragment>
    </Paper>
  );
}
