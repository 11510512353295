import { Fragment } from "react";
import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import Buttons from "../../../components/Buttons";
import { PrimaryButton } from "./../../css components/Button";

import { useSelector } from "react-redux";
import { useState } from "react";

export default function FormDisplay({
  component = "",
  editState,
  categories,
  location,
  languages,
  formikProps,
  setEditState,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const { dashboardState } = useSelector((state) => state.memberDetails);

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={!formikProps.values.brandID && "My Profile"}
          twoText={!formikProps.values.brandID && true}
          firstText={"Welcome "}
          secondText={"Let's setup your brand."}
          fontSize="25px"
          pageName={!!formikProps.values.brandID && "My Profile"}
          buttons={[
            <Buttons
              ID={formikProps.values.brandID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              condition={component === "new" ? false : true}
            />,
            component === "" && (
              <PrimaryButton
                variant="outlined"
                size="large"
                sx={{
                  display:
                    Boolean(
                      dashboardState !== "promotion" &&
                        !formikProps.values.publisher.isPublisher
                    ) && "none",
                }}
                onClick={() => setShowDialog(true)}
              >
                Show AD-Tag
              </PrimaryButton>
            ),
          ]}
        />
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            component={component}
            formikProps={formikProps}
            editState={editState}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTwo
            component={component}
            categories={categories}
            location={location}
            languages={languages}
            formikProps={formikProps}
            editState={editState}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
