import * as Yup from "yup";
import { toInternationalFormat } from "../../../utils";

export const createCampaignObject = {
  brandID: "",
  audienceID: "",
  campaignID: "",
  startTime: "",
  previewImage: "",
  isEditable: true,
  campaignStatus: "",
  brandDetails: {
    brandName: "",
    brandLogo: "",
  },

  file_name: {
    value: "",
  },

  campaign_name: {
    value: "",
  },

  about: {
    value: "",
  },

  destination_url: {
    value: "",
  },

  start_duration: {
    value: "",
  },

  end_duration: {
    value: "",
  },

  volume: {
    value: "",
  },

  budget_min: {
    value: "",
  },

  budget_max: {
    value: "",
  },

  mau: {
    value: "",
  },

  conversion_type: {
    value: "",
  },

  payout_percentage: {
    value: "",
  },

  payout_terms: {
    value: "",
  },

  trail_period: {
    value: "",
  },

  category: {
    value: [],
  },

  creatives: [],
};

export function objectFromFormData(formData, activeBrand) {
  let sDate = new Date(formData.start_duration.value);
  let eDate = new Date(formData.end_duration.value);

  let fields = [
    { file_name: formData.file_name },

    {
      campaign_name: formData.campaign_name,
    },

    {
      about: formData.about,
    },

    {
      destination_url: formData.destination_url,
    },

    {
      start_duration: {
        value: sDate.toISOString(),
      },
    },

    {
      end_duration: { value: eDate.toISOString() },
    },

    {
      volume: {
        value: formData.volume.value.replace(/[,]/g, ""),
      },
    },

    {
      budget_min: {
        value: formData.budget_min.value.replace(/[,]/g, ""),
      },
    },

    {
      budget_max: {
        value: formData.budget_max.value.replace(/[,]/g, ""),
      },
    },

    {
      mau: {
        value: formData.mau.value.replace(/[,]/g, ""),
      },
    },

    {
      category: formData.category,
    },

    {
      conversion_type: formData.conversion_type,
    },

    {
      payout_percentage: formData.payout_percentage,
    },

    {
      payout_terms: formData.payout_terms,
    },

    {
      trail_period: formData.trail_period,
    },
  ];

  let data = new FormData();

  data.append("brand_id", parseInt(activeBrand.ID));
  data.append(
    "audience_id",
    !!formData.audienceID ? formData.audienceID : null
  );
  data.append("previewImage", formData.previewImage);
  data.append("fields", JSON.stringify(fields));
  return data;
}

export function creative(formData, campaignID, creativeData) {
  let data = new FormData();

  if (creativeData.creativeID) {
    if (creativeData.upload === "local" && formData.upload === "link") {
      data.append("link", formData.fileUrl);
      data.append("creative", null);
    }
    if (creativeData.upload === "link" && formData.upload === "local") {
      data.append("creative", formData.creative);
      data.append("link", "");
    }
    if (creativeData.creative && formData.creative !== creativeData.creative) {
      data.append("creative", formData.creative);
    }

    if (creativeData.fileUrl && formData.fileUrl !== creativeData.fileUrl) {
      data.append("link", formData.fileUrl);
    }
  } else if (!creativeData.creativeID) {
    if (formData.creative) {
      data.append("creative", formData.creative);
    }

    if (formData.fileUrl) {
      data.append("link", formData.fileUrl);
    }
  }

  data.append("campaign_id", campaignID);
  data.append("size", formData.adSize);
  data.append("adtype_id", formData.adSize);
  data.append("format", formData.format);
  data.append("fileName", formData.fileName);
  data.append("creative_name", formData.friendlyName);
  data.append("ad_tag", formData.adTag);
  return data;
}

export function objectFromResponse(response) {
  let data = {};
  let creativesData = [];
  for (let key of response.campaignData) {
    if (Object.keys(key)[0] === "category") {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || [],
      };
    }
    if (
      Object.keys(key)[0] === "budget_min" ||
      Object.keys(key)[0] === "budget_max" ||
      Object.keys(key)[0] === "mau" ||
      Object.keys(key)[0] === "volume"
    ) {
      data[Object.keys(key)[0]] = {
        value:
          (Object.values(key)[0] &&
            toInternationalFormat(Object.values(key)[0])) ||
          "",
      };
    } else {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || "",
      };
    }
  }

  for (let create of response.creatives) {
    creativesData.push({
      creativeID: create.creative_id,
      format: create.banner_format,
      creative: create.file_path,
      fileUrl: create.url,
      friendlyName: create.creative_name,
      fileName: create.file_path,
      adSize: create.banner_size,
      adTag: create.ad_tag,
    });
  }

  return {
    ...data,
    creatives: creativesData,
    brandID: response.brand_id,
    fileName: response.fileName,
    adminCampaignStatus: response.admin_campaign_status,
    campaignStatus: response.campaignStatus,
    campaignID: response.campaign_id,
    isEditable: response.is_editable,
    previewImage: response.preview_image,
    audienceID:
      response.audience.audience_id === -1
        ? false
        : response.audience.audience_id,
    campaignAdminStatus: response.admin_campaign_status,
  };
}

export const validationSchema = Yup.object().shape({
  campaign_name: Yup.object().shape({
    value: Yup.string().required("Campaign Name is required").min(3),
  }),

  previewImage: Yup.mixed().required("Preview Image is required"),

  start_duration: Yup.object().shape({
    value: Yup.date().required("Start Date is required"),
  }),

  startTime: Yup.date(),

  end_duration: Yup.object().shape({
    value: Yup.date().required("End Date is required"),
  }),

  mau: Yup.object().shape({
    value: Yup.string().required("Monthly active users is required"),
  }),

  budget_min: Yup.object().shape({
    value: Yup.string().required("Minimum budget is required"),
  }),

  budget_max: Yup.object().shape({
    value: Yup.string().required("Maximum budget is required"),
  }),

  destination_url: Yup.object().shape({
    value: Yup.string()
      .matches(
        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
        "Enter correct url!"
      )
      .required("Destination url is required"),
  }),

  conversion_type: Yup.object().shape({
    value: Yup.string().required("Conversion Type is required"),
  }),
});
