import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./connectionObject";

export default function Form({ formData, editState, setEditState }) {
  const axios = useAxios();
  const { alert } = useAlert();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberDetails = useSelector((state) => state.memberDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/brand/audience";
        let method = "POST";

        if (!!formData.ID) {
          url = "/brand/audience/" + formData.ID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData, memberDetails),
        })
          .then((res) => {
            if (res.success) {
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
        />
      )}
    </Formik>
  );
}
