import queryString from "query-string";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components";

import Form from "./Form";
import { useAxios, useComponent } from "../../../hooks";
import {
  createCampaignObject,
  objectFromResponse,
} from "../create campaign components/campaignObject";
import { audienceObject } from "../create campaign components/audience details components/audienceObjects";
import BASE_URL from "../../../constants/baseURL";
import { objectFromBrandResponse } from "../brand details components/brandDetailsObject";
import { toInternationalFormat } from "../../../utils";

export default function SuperadminCampaignDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const campaignID = params.campaignID || "";
  const brandID = queryString.parse(window.location.search).brandID;

  const [audiences, setAudiences] = useState([]);
  const [brandDetails, setBrandDetails] = useState({
    data: {},
    icon: "",
  });
  const [editState, setEditState] = useState(false);
  const [connections, setConnections] = useState([]);
  const [formData, setFormData] = useState(createCampaignObject);
  const [audienceData, setAudienceData] = useState(audienceObject);
  const [retry, setRetry] = useState({});

  const { categories, userID } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start(5);

    axios({
      url: "/getBrand/" + brandID,
      method: "GET",
    }).then((response) => {
      if (response.success) {
        setBrandDetails({
          data: objectFromBrandResponse(response.data),
          icon: BASE_URL.mox_api.url + response.data.logo,
        });
        loader.apiComplete();
      }
    });

    axios({ url: "/brand/getAllAudiences?brandId=" + brandID }).then((res) => {
      if (res.success) {
        let arr = [];

        for (let key of res.data) {
          let data = {};
          for (let fields of key.fields) {
            data[Object.keys(fields)[0]] = Object.values(fields)[0];
          }
          arr.push({
            ...data,
            audience_id: key.audience_id,
          });
        }
        setAudiences(arr);

        loader.apiComplete();
      }
    });

    if (!!campaignID) {
      let campaignData = {};
      axios({
        url:
          "/user/brand/getCampaign?brandId=" +
          parseInt(brandID) +
          "&campaignId=" +
          parseInt(campaignID),
        method: "GET",
      }).then(async (response) => {
        if (response.success) {
          axios({
            url:
              "/brand/getDetailsOfAudience/" +
              response.data.audience.audience_id,
          }).then(async (res) => {
            if (res.success) {
              let data = {};

              for await (let key of res.data.fields) {
                data[Object.keys(key)[0]] = {
                  value: Object.values(key)[0],
                  mandatory: Object.values(key)[1],
                };
              }

              data = {
                ...data,
                ID: res.data.audience_id,
                brandID: res.data.brand_id,
              };

              await setAudienceData(data);
              loader.apiComplete(1);
            }
          });
          await axios({
            url: "/user/brand/campaign/creatives?campaignId=" + campaignID,
          }).then(async (creatives) => {
            if (creatives.success) {
              campaignData = {
                ...response.data,
                creatives: creatives.data,
              };
              let connection = [];
              for (let con of response.data.connections) {
                if (con.userDbStatus === "FAILED" || con.status === "FAILED") {
                  setRetry({
                    [con.sender_brandId]: false,
                  });
                }
                connection.push({
                  requestStatus: con.requestStatus?.replace(/_/g, " "),
                  logo: con.receiver_brand_info?.logo,
                  mau:
                    (con.receiver_brand_info?.mau &&
                      toInternationalFormat(con.receiver_brand_info?.mau)) ||
                    "",
                  brandId: con.receiver_brand_info?.brandId,
                  category: con.receiver_brand_info?.category || [],
                  languages: con.receiver_brand_info?.languages || [],
                  locations: con.receiver_brand_info?.location || [],
                  name: con.receiver_brand_info?.brand_name || "",
                  expectedCount:
                    con.total_users_count &&
                    toInternationalFormat(con.total_users_count),
                  usersCount:
                    con.users_count && toInternationalFormat(con.users_count),
                  totalUsersCount: con.total_users_count,
                  dbStatus: con.userDbStatus || "",
                  pidStatus: con.status || "",
                  senderUserId: con.sender_userId,
                  senderBrandId: con.sender_brandId,
                  senderCampaignId: con.sender_campaignId,
                  receiverUserId: con.receiver_userId,
                  receiverBrandId: con.receiver_brandId,
                  pidCount:
                    con.users_count && toInternationalFormat(con.users_count),
                  userDbCount:
                    con.kevel_pid_upload_count &&
                    toInternationalFormat(con.kevel_pid_upload_count),
                });
              }

              setConnections(connection);
              await setFormData(objectFromResponse(campaignData));
              loader.apiComplete(2);
            }
          });
        }
      });
    } else {
      loader.apiComplete(3);
    }
  }, [brandID, axios, loader, campaignID, userID]);

  return (
    <Loader>
      <Form
        retry={retry}
        setRetry={setRetry}
        categories={categories}
        audiences={audiences}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        audienceData={audienceData}
        connections={connections}
        brandDetails={brandDetails}
        setBrandDetails={setBrandDetails}
        component={"superadmin"}
        setConnections={setConnections}
      />
    </Loader>
  );
}
