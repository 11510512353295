import { useTheme } from "@mui/material/styles";
import { Box, Divider } from "@mui/material";
import styled from "styled-components/macro";

import Menu from "./sidebar components/Menu";
import Logo from "../../assets/Mox (2).png";
import Shortcuts from "./sidebar components/Shortcuts";
import pages from "../../constants/pages";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  transition: 0.2s ease;
  overflow: hidden;
`;

const ImageDiv = styled.div`
  width: 65px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 39px;
  cursor: pointer;
`;

const Image = styled.img`
  height: inherit;
  width: inherit;
`;

export default function Sidebar({ sidebarState, onMouseEnter, onMouseLeave }) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        width: sidebarState.open ? "250px" : "100px",
        background: theme.sidebar.background,
      }}
    >
      <ImageDiv onClick={() => navigate(pages.home.route)}>
        <Image src={Logo} alt="!" />
      </ImageDiv>

      <Divider />

      <Menu sidebarState={sidebarState} />

      <Shortcuts sidebarState={sidebarState} />
    </Wrapper>
  );
}
