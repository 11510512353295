import { useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./audienceObjects";

export default function Form({
  categories,
  location,
  languages,
  campaignID,
  formData,
  component,
  editState,
  setEditState,
  setAudienceCreated,
  campaignFormikProps,
  handleClose,
  dialogDetails,
  percentageSliderValue,
  setPercentageSliderValue,
}) {
  const axios = useAxios();

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);

  const memberDetails = useSelector((state) => state.memberDetails);

  const [percentageSliderValueDialog, setPercentageSliderValueDialog] =
    useState(percentageSliderValue === undefined ? -1 : percentageSliderValue);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/brand/audience";
        let method = "POST";

        if (!!formData.ID) {
          url = "/brand/audience/" + formData.ID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          disableRedirect: true,
          data: objectFromFormData(formData, memberDetails),
        })
          .then((res) => {
            if (res.success) {
              if (component !== "dialog") {
                if (!!formData.ID) {
                  setEditState(false);
                  dispatch(
                    alert({
                      message: "Audience updated successfully!",
                      type: "success",
                    })
                  );
                } else {
                  dispatch(
                    alert({
                      message: "Audience created successfully!",
                      type: "success",
                    })
                  );
                  navigate(pages.myCampaign.route);
                }
              } else {
                setAudienceCreated(res.data.audience_id);
                campaignFormikProps.setValues((prev) => ({
                  ...prev,
                  audienceID: res.data.audience_id,
                }));
                handleClose();
              }

              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          edit={edit}
          setEdit={setEdit}
          categories={categories}
          location={location}
          languages={languages}
          handleClose={handleClose}
          component={component}
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
          campaignID={campaignID}
          dialogDetails={dialogDetails}
          setAudienceCreated={setAudienceCreated}
          campaignFormikProps={campaignFormikProps}
          percentageSliderValue={
            typeof percentageSliderValue !== "undefined"
              ? percentageSliderValue
              : percentageSliderValueDialog
          }
          setPercentageSliderValue={
            typeof percentageSliderValue !== "undefined"
              ? setPercentageSliderValue
              : setPercentageSliderValueDialog
          }
        />
      )}
    </Formik>
  );
}
