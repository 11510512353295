import { get } from "lodash";
import { Fragment } from "react";
import styled from "styled-components/macro";
import {
  Grid,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  Autocomplete,
  Slider,
} from "@mui/material";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";

import pages from "../../../../constants/pages";
import { useNavigate } from "react-router-dom";
import { Label, TextField } from "../../../../components";

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

const PercentageSlider = styled(Slider)({
  color: "#fff",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
    background: "#02a0fc",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid #02a0fc",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 50,
    height: 32,
    backgroundColor: "#02a0fc",
  },
});

export default function AudienceForm({
  audienceData,
  location,
  languages,
  component,
  formikProps,
  setAudienceData,
}) {
  const navigate = useNavigate();
  var gender = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];

  function calculateNonMandatoryCount(data) {
    let count = 0;
    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        typeof data[key] === "object" &&
        data[key].hasOwnProperty("mandatory") &&
        data[key]?.value !== undefined &&
        data[key]?.value !== null &&
        data[key]?.value !== "" &&
        data[key]?.value.length !== 0 &&
        data[key]?.value.min !== ""
      ) {
        if (
          key !== "audience_group" &&
          key !== "percentage" &&
          key !== "approx_mau"
        ) {
          if (data[key].mandatory === false) {
            count++;
          }
        }
      }
    }
    return count;
  }

  const nonMandatoryCount = calculateNonMandatoryCount(audienceData);

  function handleCheckChange(e) {
    let { name } = e.target;
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
    }));

    function setValues(val) {
      return {
        ...val,
        mandatory: e.target.checked,
      };
    }
  }

  return (
    <Fragment>
      <Grid item xs={6}>
        <Label title={"Audience Group"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="audience_group.value"
          placeholder="Audience Group"
          inputProps={{ "aria-label": "Without label" }}
          disabled
          value={audienceData?.audience_group?.value}
        />
      </Grid>

      <Grid item xs={6} style={{ marignTop: "10px" }}>
        <Label title="Age Range" />
      </Grid>

      <Grid item xs={4} style={{ display: "flex", justifyContent: "end" }}>
        <Heading
          style={{ color: audienceData.age?.mandatory ? "red" : "gray" }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled
          name="age.mandatory"
          onChange={handleCheckChange}
          checked={audienceData.age?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="age.value.min"
          placeholder="From Age"
          value={audienceData?.age?.value?.min}
          inputProps={{ "aria-label": "Without label" }}
          disabled
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="age.value.max"
          placeholder="To Age"
          disabled
          value={audienceData?.age?.value?.max}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6} style={{ marignTop: "10px" }}>
        <Label title="Gender" />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: audienceData.gender?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          name="gender.mandatory"
          disabled
          onChange={handleCheckChange}
          checked={audienceData?.gender?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          select
          name="gender.value"
          disabled
          value={audienceData?.gender?.value}
          sx={{
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          }}
          SelectProps={{
            renderValue: (selected) => {
              return renderingGenderValue(selected, gender).join(", ");
            },
            multiple: true,
            value: get(audienceData, "gender.value", []),
          }}
        >
          {gender?.map((item, _) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox
                checked={
                  get(audienceData, "gender.value", []).indexOf(item.value) > -1
                }
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} style={{ marignTop: "10px" }}>
        <Label title="ARPU" />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: audienceData?.arpu?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          name="arpu.mandatory"
          disabled
          onChange={handleCheckChange}
          checked={audienceData?.arpu?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="arpu.value.min"
          placeholder="From ARPU"
          value={audienceData?.arpu?.value?.min}
          inputProps={{ "aria-label": "Without label" }}
          disabled
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="arpu.value.max"
          placeholder="To ARPU"
          disabled
          value={audienceData?.arpu?.value?.max}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6} style={{ marignTop: "10px" }}>
        <Label title={"Location"} />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: audienceData.audience_location?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled
          name="audience_location.mandatory"
          onChange={handleCheckChange}
          checked={audienceData.audience_location?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Autocomplete
          disabled
          multiple
          forcePopupIcon={false}
          fullWidth
          value={audienceData?.audience_location?.value}
          options={location}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) =>
            option.country_name === value
          }
          getOptionLabel={(option) => option.country_name}
          renderTags={(value) =>
            value.map((val) => (value.length === 1 ? val : val)).join(", ")
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={
                  get(audienceData, "audience_location.value", "").indexOf(
                    option.country_name
                  ) > -1
                }
              />
              {option.country_name}
            </li>
          )}
          renderInput={(params) => <TextField multiline {...params} />}
        />
      </Grid>

      <Grid item xs={6} style={{ marignTop: "10px" }}>
        <Label title={"Language"} />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: audienceData.languages?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled
          name="languages.mandatory"
          onChange={handleCheckChange}
          checked={audienceData.languages?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Autocomplete
          disabled
          multiple
          fullWidth
          forcePopupIcon={false}
          value={audienceData?.languages?.value}
          options={languages}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.language === value}
          getOptionLabel={(option) => option.language}
          renderTags={(value) =>
            value.map((val) => (value.length === 1 ? val : val)).join(", ")
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={
                  get(audienceData, "languages.value", "").indexOf(
                    option.language
                  ) > -1
                }
              />
              {option.language}
            </li>
          )}
          renderInput={(params) => <TextField multiline {...params} />}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Non mandatory count"} />
      </Grid>

      <Grid item xs={10} mt={"10"}>
        <PercentageSlider
          step={1}
          marks
          valueLabelDisplay="on"
          value={audienceData?.percentage?.value}
          disabled
          // min={0}
          max={nonMandatoryCount}
          style={{ color: "#808080" }}
        />
      </Grid>

      {component === "" && !!formikProps.values.campaignID && (
        <Grid item xs={12}>
          <Typography
            onClick={() =>
              navigate(pages.audienceDetails.route + audienceData.ID)
            }
            style={{ color: "#02a0fc", cursor: "pointer" }}
          >
            Edit Audience Group Details
          </Typography>
        </Grid>
      )}
    </Fragment>
  );

  function renderingGenderValue(value, array) {
    var arr = [];

    if (value.length > 0) {
      value.map((item) => arr.push(array.find((x) => x.value === item).label));
    }
    return arr;
  }
}
