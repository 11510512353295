import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Avatar, Grid, Typography } from "@mui/material";

import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import BASE_URL from "../../../constants/baseURL";
import {
  ActiveTextEighteen,
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  padding-right: 27px;
`;

const ImageDiv = styled.img``;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const BrandName = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.4;
  text-transform: capitalize;
`;

const BrandImage = styled(Avatar)`
  height: 70px;
  width: 70px;
  position: absolute;
  top: 69px;
  left: 41px;
`;

export default function RequestedList({ campaigns, component }) {
  const navigate = useNavigate();

  function convertDate(d) {
    let date = new Date(d);

    return (
      date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    );
  }

  function handleRedirection(campaign) {
    if (component === "request") {
      navigate(
        pages.campaignRequestDetails.route +
          campaign.sender_campaignId +
          "?brandID=" +
          campaign.sender_brandId +
          "&sender_id=" +
          campaign.sender_userId
      );
    }

    if (component === "accepted") {
      navigate(
        pages.campaignAcceptedDetails.route +
          campaign.campaign_id +
          "?brandID=" +
          campaign.brand_id +
          "&sender_id=" +
          campaign.sender_userId
      );
    }
    if (component === "") {
      navigate(pages.campaignDetails.route + campaign.campaign_id);
    }
  }

  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  return (
    <Grid container spacing={3}>
      {campaigns.map((campaign) => (
        <Grid item xs={12} key={campaign.sender_campaignId}>
          <Paper>
            <Grid item alignSelf="center" paddingRight="59px">
              <div style={{ position: "relative" }}>
                <BrandImage
                  src={
                    BASE_URL.mox_api.url + campaign.brand.logo ||
                    "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png"
                  }
                />
                <ImageDiv
                  className="campaign-img"
                  src={
                    campaign.previewImage ||
                    "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png"
                  }
                  onError={onImageError}
                  alt="!"
                />
              </div>
            </Grid>

            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item>
                  <BrandName>{campaign.brand.name}</BrandName>
                  <HeadingEighteen>{campaign.campaign_name}</HeadingEighteen>
                </Grid>

                <Grid item xs />

                <Grid item alignSelf={"center"} display="flex">
                  <svg
                    style={{ position: "relative", margin: "9px 9px" }}
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="5"
                      fill={
                        campaign.status === "PENDING" ||
                        campaign.status === "REQUESTED"
                          ? colors.warning
                          : campaign.status === "ACCEPTED"
                          ? colors.success
                          : colors.error
                      }
                    />
                  </svg>

                  <ActiveTextEighteen
                    color={
                      campaign.status === "PENDING" ||
                      campaign.status === "REQUESTED"
                        ? colors.warning
                        : campaign.status === "ACCEPTED"
                        ? colors.success
                        : colors.error
                    }
                    variant="button"
                  >
                    {campaign.status?.replace(/_/g, " ")}
                  </ActiveTextEighteen>
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={3}>
                  <TextFourteenBlack>Campaign Period</TextFourteenBlack>
                  <HeadingEighteen>
                    {convertDate(campaign.start_duration)}&nbsp; - &nbsp;
                    {convertDate(campaign.end_duration)}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Conversion Type</TextFourteenBlack>
                  <HeadingEighteen>
                    {campaign.conversion_type || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Desired Volume</TextFourteenBlack>
                  <HeadingEighteen>{campaign.volume || "N.A"}</HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Budget</TextFourteenBlack>
                  <HeadingEighteen>
                    {campaign.budget_min} - {campaign.budget_max}
                  </HeadingEighteen>
                </Grid>

                <Grid
                  item
                  xs={1}
                  textAlign={"end"}
                  alignSelf="end"
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Link
                    style={{ cursor: "pointer", paddingRight: "0px" }}
                    onClick={() => handleRedirection(campaign)}
                  >
                    View
                  </Link>
                  <ArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
