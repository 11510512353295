import * as Yup from "yup";

export const onboardingObject = {
  connectionID: "",
  brandID: "",
  connectionUrl: "",
  noDocker: false,
  port: "",
  token: "",
  fields: [],
  mappedFields: [],
  fieldNames: "",
  connection: false,
};

export function objectFromFormData(formData, mappedFields) {
  if (formData.noDocker) {
    return {
      brand_id: formData.brandID,
    };
  } else {
    let fieldMappings = {};
    for (let field of formData.fields) {
      if (field === undefined) {
        continue;
      }
      if (
        field !== undefined &&
        Object.values(field)[0] !== "" &&
        mappedFields[Object.keys(field)[0]] !== ""
      ) {
        fieldMappings[Object.keys(field)[0]] = Object.values(field)[0];
      } else if (
        field !== undefined &&
        Object.values(field)[0] === "" &&
        mappedFields[Object.keys(field)[0]] !== "" &&
        mappedFields[Object.keys(field)[0]] !== undefined
      ) {
        fieldMappings[Object.keys(field)[0]] = null;
      }
    }

    if (!formData.connectionID) {
      return {
        brand_id: formData.brandID,
        connectionDetails: {
          connection_url: formData.connectionUrl,
          port: formData.port,
          token: formData.token,
        },
        fieldMappings: fieldMappings,
      };
    } else {
      return {
        brand_id: formData.brandID,
        connectionDetails: {
          connection_url: formData.connectionUrl,
          port: formData.port,
          token: formData.token,
        },
        fieldMappings: fieldMappings,
      };
    }
  }
}

export function objectFromResponse(responseData, fieldsMapped) {
  let values = {};
  let fields = [];
  if (responseData.fieldMappings.length > 0) {
    for (let field of responseData.fieldMappings) {
      values[field.field_name] = field.field_value;
    }

    for (let field of fieldsMapped) {
      if (values[field.field_name] === undefined) {
        fields.push({ [field.field_name]: "" });
      } else {
        fields.push({ [field.field_name]: values[field.field_name] });
      }
    }
  }
  return {
    fields: fields,
    noDocker: responseData.connectionDetails.no_docker,
    mappedFields: values,
    brandDetails: responseData.brandDetails,
    port: responseData.connectionDetails.port,
    fieldNames: responseData.fieldsNames || "",
    connection: responseData.connection || false,
    token: responseData.connectionDetails.token,
    brandID: responseData.connectionDetails.brand_id,
    connectionID: responseData.connectionDetails.connection_id,
    connectionUrl: responseData.connectionDetails.connection_url,
  };
}

export const validationSchema = Yup.object().shape({
  brandID: Yup.string().required("Brand ID is required"),
  connectionUrl: Yup.string().matches(
    /((https?):\/\/)\d+(\.\d+)*$/,
    "Enter correct url!"
  ),
  port: Yup.string(),
});
