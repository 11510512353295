import { Formik } from "formik";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { signInMember } from "../../../redux/slices/memberSlice";
import { useAxios, useAlert, useAxiosVerismart } from "../../../hooks";
import { logInDetailsObject, validationSchema } from "./logInDetailsObject";
import VerifyDialog from "../sign up components/verify account components/VerifyDialog";

export default function Form() {
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState(false);

  const brandAxios = useAxiosVerismart();
  const axios = useAxios({ disableAuthToken: true });

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={logInDetailsObject}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/auth/login",
            method: "POST",
            disableRedirect: true,
            data: {
              email: formData.email,
              password: formData.password,
            },
          })
            .then(async (response) => {
              if (response.success) {
                if (response.data?.user[0]?.account_status !== 1) {
                  switch (true) {
                    case !response.data?.user[0]?.is_email_verified:
                      setDetail("Check your Mail box to verify your ID.");
                      break;
                    case response.data.user[0]?.account_status === 0:
                      setDetail(
                        "Please Wait !! Your account has not been verified by admin yet."
                      );
                      break;
                    case response.data.user[0]?.account_status === 2:
                      setDetail("Your account has been blocked");
                      break;
                    case response.data.user[0]?.account_status === 3:
                      setDetail("Your account has been rejected");
                      break;
                    case response.data.user[0]?.account_status === 4:
                      setDetail("Your account has been suspended");
                      break;
                    case response.data.user[0]?.account_status === 5:
                      setDetail("Your account has been deleted");
                      break;
                    default:
                      setDetail("Something went wrong");
                      break;
                  }
                } else {
                  sessionStorage.setItem(
                    "role",
                    response.data.user[0]?.role_name
                  );
                  dispatch(alert({ message: "Welcome!", type: "success" }));
                  if (response.data.user[0]?.role_name === "NORMAL_USER") {
                    brandAxios({
                      url:
                        "/brand?businessEntityId=" +
                        response.data.user[0]?.business_entity_id,
                      method: "GET",
                      customAuthToken: response.data.token,
                    }).then(async (brands) => {
                      if (brands.success) {
                        if (brands.data.length > 0) {
                          await dispatch(
                            signInMember({
                              authToken: response.data.token,
                              userID: parseInt(response.data.user[0]?.user_id),
                              businessEntityId: parseInt(
                                response.data.user[0]?.business_entity_id
                              ),
                            })
                          );
                          navigate(pages.welcomePage.route);
                        } else {
                          await dispatch(
                            signInMember({
                              authToken: response.data.token,
                              userID: parseInt(response.data.user[0]?.user_id),
                              businessEntityId: parseInt(
                                response.data.user[0]?.business_entity_id
                              ),
                            })
                          );

                          navigate(pages.brandCreate.route);
                        }
                      }
                    });
                  } else {
                    await dispatch(
                      signInMember({
                        authToken: response.data.token,
                        userID: parseInt(response.data.user[0]?.user_id),
                        businessEntityId: parseInt(
                          response.data.user[0]?.business_entity_id
                        ),
                      })
                    );
                    navigate(pages.accountVerification.route);
                  }
                }
              } else {
                dispatch(alert({ message: response.message, type: "error" }));
              }
              setSubmitting(false);
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <FormDisplay formikProps={formikProps} />
          </form>
        )}
      </Formik>

      <VerifyDialog detail={detail} setDetail={setDetail} />
    </Fragment>
  );
}
