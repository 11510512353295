import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import BASE_URL from "../../../constants/baseURL";
import { HeadingEighteen } from "../../css components/Style";

const Link = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
`;

const Subtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;
const Image = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
`;

export default function List({ dpList }) {
  const navigate = useNavigate();

  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  return (
    <Grid container spacing={3}>
      {dpList?.map((connection) => (
        <Grid item xs={12} key={connection.brand_id}>
          <Paper>
            <Grid item alignSelf="center">
              <Image
                alt="1"
                src={BASE_URL.mox_api.url + connection.brandDetails.logo}
                onError={onImageError}
              />
            </Grid>

            <Grid item mr="20px" />

            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs>
                  <HeadingEighteen>
                    {connection?.brandDetails?.brand_name}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs />

                <Grid item xs={12} />

                <Grid
                  item
                  xs={4}
                  alignSelf="end"
                  style={{ paddingTop: "35px" }}
                >
                  <Subtitle>Connection Url</Subtitle>
                  <HeadingEighteen>
                    {connection?.connectionDetails?.connection_url || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={4} alignSelf="end">
                  <Subtitle>Port</Subtitle>
                  <HeadingEighteen>
                    {connection?.connectionDetails?.port || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2} />

                <Grid
                  item
                  xs={2}
                  alignSelf={"end"}
                  textAlign="end"
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Link
                    style={{ cursor: "pointer", paddingRight: "0" }}
                    onClick={() =>
                      navigate(
                        pages.dpOnBoardingDetails.route +
                          connection.connectionDetails.connection_id +
                          "?brandID=" +
                          connection?.connectionDetails?.brand_id
                      )
                    }
                  >
                    View
                  </Link>
                  <ArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
