const pages = {
  //Dashboard Module

  signIn: {
    ID: "sign-in",
    route: "/sign-in",
  },

  signUp: {
    ID: "sign-up",
    route: "/sign-up",
  },

  home: {
    ID: "home",
    route: "/",
  },

  verifyAccount: {
    ID: "verify-account",
    route: "/verify/",
  },

  brandCreate: {
    ID: "create-brand",
    route: "/create-brand",
  },

  brandDetails: {
    ID: "brand-details",
    route: "/brand-details",
  },

  newBrandCreate: {
    ID: "brand-create-new",
    route: "/brand-create-new",
  },

  myCampaign: {
    ID: "my-campaign",
    route: "/all-campaign",
  },

  myBilling: {
    ID: "my-billings",
    route: "/all-billings",
  },

  billingReport: {
    ID: "billing-report",
    route: "/billing-report",
  },

  billingDetails: {
    ID: "billing-details",
    route: "/billing-details",
  },

  campaignRequest: {
    ID: "dp-campaign",
    route: "/campaigns",
  },

  createCampaign: {
    ID: "create-campaign",
    route: "/create-campaign",
  },

  campaignDetails: {
    ID: "campaign-details",
    route: "/campaign-details/",
  },

  campaignRequestDetails: {
    ID: "campaign-request-details",
    route: "/campaign-request-details/",
  },

  campaignAccepted: {
    ID: "campaign-accepted",
    route: "/campaign-accepted",
  },

  campaignAcceptedDetails: {
    ID: "campaign-accepted-details",
    route: "/campaign-accepted-details/",
  },

  audienceList: {
    ID: "audience-list",
    route: "/all-audience",
  },

  createAudience: {
    ID: "create-audience",
    route: "/create-audience",
  },

  audienceDetails: {
    ID: "audience-details",
    route: "/audience-details/",
  },

  exploreConnections: {
    ID: "explore-connections",
    route: "/my-connections",
  },

  connectionDetails: {
    ID: "connection-details",
    route: "/connection-details/",
  },

  welcomePage: {
    ID: "welcome-page",
    route: "/welcome-page",
  },

  // super admin pages

  brandsList: {
    ID: "administrator-brands",
    route: "/brands",
  },

  usersList: {
    ID: "users-list",
    route: "/users/",
  },

  accountVerification: {
    ID: "verify-account",
    route: "/accounts-verification",
  },

  createUser: {
    ID: "create-user",
    route: "/create-user",
  },

  userDetails: {
    ID: "user-details",
    route: "/user-details/",
  },

  superAdminBrandCampaigns: {
    ID: "brand-campaigns",
    route: "/brand-campaigns/",
  },

  superAdminCampaignDetails: {
    ID: "brand-campaign-details",
    route: "/brand-campaign-details/",
  },

  superAdminAllCampaigns: {
    ID: "all-campaign",
    route: "/campaigns-all",
  },

  dpOnBoardingList: {
    ID: "all-boarding",
    route: "/on-boarded-connections",
  },

  dpOnBoarding: {
    ID: "dp-on-boarding",
    route: "/dp-on-boarding",
  },

  dpOnBoardingDetails: {
    ID: "dp-on-boarding",
    route: "/dp-on-boarding-details/",
  },

  changePassword: {
    ID: "change-password",
    route: "/change-password",
  },

  resetEmailPasswordPage: {
    ID: "reset-email-password",
    route: "/reset-password",
  },

  forgotPasswordPage: {
    ID: "forgot-password",
    route: "/forgot-password/",
  },
};
export default pages;
