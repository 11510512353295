import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { Typography, Grid, Avatar } from "@mui/material";

const PageName = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  color: #333;
`;

const Heading = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
`;

const BrandName = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
`;

const Image = styled(Avatar)`
  border: 1px solid #999999;
  height: 70px;
  width: 70px;
`;

export default function CampaignHeader({
  title = "",
  pageName,
  firstText,
  secondText,
  heading,
  brandName,
  iconImage,
  buttons = [],
}) {
  return (
    <React.Fragment>
      <Helmet title={title || pageName || firstText + secondText} />

      <Grid p="20px 0" mb="10px" container alignItems="center">
        <Grid item>
          {!iconImage ? (
            <Image>{brandName.split(" ")[0]}</Image>
          ) : (
            <Image src={iconImage} />
          )}
        </Grid>

        <Grid item xs ml="30px">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Heading>{heading}</Heading>
              <PageName variant="h3">{pageName}</PageName>
              <BrandName>by {brandName}</BrandName>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          style={{ gap: "5px" }}
        >
          {buttons.length > 0 &&
            buttons.map((icon, index) => (
              <Grid item key={index}>
                {icon}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
