import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Grid, MenuItem, Typography, Button } from "@mui/material";

import { useAxios, useComponent } from "../../../../hooks";
import { TextField, Label } from "../../../../components";
import { CancelLoadingButton } from "../../../css components/Button";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

const Creative = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: rgba(189, 189, 189, 1);
`;

const ImageDiv = styled.div`
  width: 200px;
  height: 80px;
  border: 1px solid #d6cbcb;
`;

export default function CreativeForm({
  dialogFormikProps,
  editState,
  handleClose,
  formikProps,
  component,
  adSize,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <Grid item xs={6}>
        <MainHeading>Ad Creative</MainHeading>
        <Creative>Creative Details</Creative>
      </Grid>

      {component !== "superadmin" &&
      component !== "request" &&
      dialogFormikProps.values.creativeID ? (
        <Grid item xs={6} textAlign="end">
          <CancelLoadingButton
            loading={loading}
            variant="outlined"
            onClick={onDelete}
          >
            Delete
          </CancelLoadingButton>
        </Grid>
      ) : (
        <Grid item xs={6} />
      )}

      <Grid item xs={6}>
        <Label
          title="Format"
          name="format"
          required={true}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={6}>
        {dialogFormikProps.values.format !== "ad-tag" && (
          <Label
            title="Source Type"
            name="upload"
            required={true}
            formikProps={dialogFormikProps}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          formikProps={dialogFormikProps}
          disabled={
            !editState ||
            dialogFormikProps.values.creativeID ||
            component === "superadmin"
          }
          name="format"
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: dialogFormikProps.values.format === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" selected disabled>
            Select Format Type
          </Menu>
          <Menu value="video">Video</Menu>
          <Menu value="image">Image</Menu>
          <Menu value="ad-tag">Ad-Tag</Menu>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        {dialogFormikProps.values.format !== "ad-tag" && (
          <TextField
            select
            formikProps={dialogFormikProps}
            disabled={!editState || component === "superadmin"}
            name="upload"
            sx={{
              "& .MuiSvgIcon-root": {
                display: !editState && "none",
              },
            }}
            SelectProps={{
              displayEmpty: true,
            }}
            InputProps={{
              style: {
                color: dialogFormikProps.values.upload === "" && "#bababacf",
              },
            }}
          >
            <Menu value="" selected disabled>
              Select Source Type
            </Menu>
            <Menu value="local">Upload From Device</Menu>
            <Menu value="link">Paste URL</Menu>
          </TextField>
        )}
      </Grid>

      {dialogFormikProps.values.format !== "ad-tag" && (
        <Fragment>
          {dialogFormikProps.values.upload === "local" && (
            <Fragment>
              <Grid item xs={12}>
                <Label title="Upload" />
              </Grid>

              <Grid
                item
                xs={dialogFormikProps.values.format === "image" ? 6 : 12}
              >
                <input
                  accept={`${dialogFormikProps.values.format}/*`}
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  disabled={!editState || component === "superadmin"}
                  onChange={(e) => handleFileChange(e)}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    disabled={!editState}
                    color="primary"
                    component="span"
                  >
                    Upload
                  </Button>{" "}
                  {!!dialogFormikProps.values.fileName
                    ? dialogFormikProps.values.fileName.length > 30
                      ? dialogFormikProps.values.fileName.split("/").pop()
                      : dialogFormikProps.values.fileName
                    : "No file chosen"}
                </label>
              </Grid>

              <Grid item xs={6}>
                {!!dialogFormikProps.values.previewImage &&
                  dialogFormikProps.values.creativeID &&
                  dialogFormikProps.values.format === "image" && (
                    <ImageDiv>
                      <a
                        href={dialogFormikProps.values.previewImage}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                            borderRadius: "6px",
                          }}
                          src={dialogFormikProps.values.previewImage}
                          alt="!"
                        />
                      </a>
                    </ImageDiv>
                  )}
              </Grid>
            </Fragment>
          )}

          <Fragment>
            {dialogFormikProps.values.upload === "link" && (
              <Fragment>
                <Grid item xs={12}>
                  <Label title="File URL" />
                </Grid>

                <Grid item xs={dialogFormikProps.values.creativeID ? 6 : 12}>
                  <TextField
                    name="fileUrl"
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="Enter Link"
                    disabled={!editState || component === "superadmin"}
                    formikProps={dialogFormikProps}
                  />
                </Grid>

                {dialogFormikProps.values.creativeID && (
                  <Grid item xs={6}>
                    <ImageDiv>
                      <a
                        href={dialogFormikProps.values.fileUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                            borderRadius: "6px",
                          }}
                          src={dialogFormikProps.values.fileUrl}
                          alt="!"
                        />
                      </a>
                    </ImageDiv>
                  </Grid>
                )}
              </Fragment>
            )}
          </Fragment>
        </Fragment>
      )}

      {dialogFormikProps.values.format === "ad-tag" && (
        <Fragment>
          <Grid item xs={12}>
            <Label
              title="AD Tag"
              name="adTag"
              required={dialogFormikProps.values.format === "ad-tag" && true}
              formikProps={dialogFormikProps}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              multiline
              required
              name="adTag"
              disabled={!editState || component === "superadmin"}
              formikProps={dialogFormikProps}
              placeholder="Enter ad tag"
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Label
          title="AD Size"
          name="adSize"
          required={dialogFormikProps.values.format !== "ad-tag" && true}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          select
          disabled={!editState || component === "superadmin"}
          formikProps={dialogFormikProps}
          name="adSize"
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: dialogFormikProps.values.adSize === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" selected disabled>
            Select Creative Size
          </Menu>
          {adSize.map((size) => (
            <Menu value={size.id}>
              {size.name}- {size.width}X{size.height}
            </Menu>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Friendly Name"
          name="friendlyName"
          required={true}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="friendlyName"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter Friendly Name"
          disabled={!editState || component === "superadmin"}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12} />
    </Fragment>
  );

  function handleFileChange(event) {
    const file = event.target.files[0];
    const fleSize = Math.round(file.size / 1024);

    if (fleSize <= 8 * 1024) {
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        previewImage: "",
        fileName: file.name,
        creative: file,
      }));
    } else {
      dispatch(alert.warning("File size too large."));
    }
  }

  function onDelete() {
    setLoading(true);
    axios({
      url:
        "/user/brand/campaign/creatives?creativeId=" +
        dialogFormikProps.values.creativeID,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        setLoading(false);

        dispatch(alert.success("Creative deleted successfully."));
        formikProps.setValues((prev) => ({
          ...prev,
          creatives: setCreatives(prev.creatives),
        }));

        function setCreatives(creatives) {
          let arr = creatives.filter(
            (creative) =>
              creative.creativeID !== dialogFormikProps.values.creativeID
          );
          return arr;
        }
        handleClose();
      }
    });
  }
}
