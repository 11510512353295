import { Fragment } from "react";
import { get, set } from "lodash";
import styled from "styled-components/macro";
import {
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";

import { Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  color: #02a0fc;
  font-size: 20px;
  font-weight: 600;
`;

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

const Error = styled(Typography)`
  color: rgb(211, 47, 47);
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
`;

const DpCount = styled(Typography)`
  color: #333;
  font-weight: 400;
  font-size: 13px;
`;

export default function FormOne({
  formikProps,
  editState,
  edit,
  mandatoryFalseCount,
  percentageSliderValue,
  count,
  oldMandatoryFalseCount,
  setOldMandatoryFalseCount,
  setPercentageSliderValue,
}) {
  var gender = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];

  function handleCheckChange(e) {
    let { name } = e.target;
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      percentage: setPercentage(prevVal.percentage),
    }));

    function setPercentage(percentage) {
      let p = percentage.value;

      if (
        mandatoryFalseCount <= percentageSliderValue &&
        Boolean(formikProps.values.ID)
      ) {
        if (p == percentageSliderValue && p == mandatoryFalseCount) {
          count.current = false;
        }

        if (!e.target.checked) {
          setPercentageSliderValue(mandatoryFalseCount);
          p = mandatoryFalseCount;
        } else {
          p = p - 1;
        }
      }

      if (
        mandatoryFalseCount < oldMandatoryFalseCount &&
        oldMandatoryFalseCount == percentageSliderValue &&
        !Boolean(formikProps.values.ID)
      ) {
        setPercentageSliderValue(mandatoryFalseCount);
        p = mandatoryFalseCount;
      }

      setOldMandatoryFalseCount(mandatoryFalseCount);

      return {
        ...percentage,
        value: p,
      };
    }

    function setValues(val) {
      return {
        ...val,
        mandatory: e.target.checked,
      };
    }
  }

  const color = formikProps.values.age?.mandatory ? "red" : "gray";
  const style = {
    color: `${color}`,
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Audience Group Details </MainHeading>
      </Grid>

      {!edit && (
        <Grid item xs={12}>
          <Error>
            Fill any one of the audience preferences and make at least one
            filled value as mandatory
          </Error>
        </Grid>
      )}

      <Grid item xs={12}>
        <Label
          title={"Audience Group"}
          formikProps={formikProps}
          required={true}
          name={"audience_group.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          formikProps={formikProps}
          name="audience_group.value"
          placeholder="Audience Group"
          inputProps={{ "aria-label": "Without label" }}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={6}>
        <Label title="Age Range" />
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "end" }}>
        <Heading style={style}>Mandatory</Heading>

        <Checkbox
          disabled={
            !editState ||
            !formikProps.values.age?.value?.min ||
            !formikProps.values.age?.value?.min
          }
          name="age.mandatory"
          onChange={handleCheckChange}
          checked={formikProps.values.age?.mandatory}
          icon={
            <Tooltip
              title={
                !formikProps.values.age?.value.min
                  ? "Enter minimum range to make it mandatory"
                  : "Make field mandatory"
              }
            >
              <ToggleOffOutlinedIcon fontSize="large" />
            </Tooltip>
          }
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>
      {!formikProps.values.ID && (
        <Grid item xs={10} padding="0">
          <DpCount>{formikProps.values.age?.dpCount} partners</DpCount>
        </Grid>
      )}
      <Grid item xs={5}>
        <TextField
          formikProps={formikProps}
          name="age.value.min"
          placeholder="From Age"
          onChange={handleTextChange}
          inputProps={{ "aria-label": "Without label" }}
          disabled={!editState}
          error={
            get(formikProps.touched, "age.value.max") &&
            !!get(formikProps.values, "age.value.min") &&
            get(formikProps.values, "age.value.min") &&
            Boolean(
              get(formikProps.touched, "age.value.min") &&
                get(formikProps.errors, "age.value.min")
            )
          }
          helperText={
            get(formikProps.touched, "age.value.max") &&
            !!get(formikProps.values, "age.value.min") &&
            !!get(formikProps.values, "age.value.min") &&
            get(formikProps.errors, "age.value.min")
          }
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          formikProps={formikProps}
          name="age.value.max"
          onChange={handleTextChange}
          placeholder="To Age"
          inputProps={{ "aria-label": "Without label" }}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={6}>
        <Label title="Gender" />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: formikProps.values.gender?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          name="gender.mandatory"
          disabled={
            !editState || formikProps.values.gender?.value?.length === 0
          }
          onChange={handleCheckChange}
          checked={formikProps.values.gender?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      {!formikProps.values.ID && (
        <Grid item xs={10} padding="0">
          <DpCount>{formikProps.values.gender.dpCount} partners</DpCount>
        </Grid>
      )}

      <Grid item xs={10}>
        <TextField
          select
          name="gender.value"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
          SelectProps={{
            multiple: true,
            value: get(formikProps.values, "gender.value", []),
            onChange: handleChange,
            displayEmpty: true,
            renderValue: (selected) => {
              if (selected.length === 0) return "Select Gender";
              else return renderingValue(selected, gender).join(", ");
            },
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          InputProps={{
            style: {
              color:
                formikProps.values.gender?.value?.length === 0 && "#bababacf",
            },
          }}
        >
          {gender?.map((item, _) => (
            <Menu key={item.value} value={item.value}>
              <Checkbox
                checked={
                  get(formikProps.values, "gender.value", "")?.indexOf(
                    item.value
                  ) > -1
                }
              />
              <ListItemText primary={item.label} />
            </Menu>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <Label title="ARPU" />
      </Grid>
      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: formikProps.values.arpu?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          name="arpu.mandatory"
          disabled={
            !editState ||
            !formikProps.values.arpu?.value?.min ||
            !formikProps.values.arpu?.value?.max
          }
          onChange={handleCheckChange}
          checked={formikProps.values.arpu?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>
      {!formikProps.values.ID && (
        <Grid item xs={10} padding="0">
          <DpCount>{formikProps.values.arpu.dpCount} partners</DpCount>
        </Grid>
      )}
      <Grid item xs={5}>
        <TextField
          formikProps={formikProps}
          name="arpu.value.min"
          disabled={!editState}
          onChange={handleTextChange}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter min ARPU"
          error={
            get(formikProps.touched, "arpu.value.max") &&
            !!get(formikProps.values, "arpu.value.min") &&
            get(formikProps.values, "arpu.value.min") &&
            Boolean(
              get(formikProps.touched, "arpu.value.min") &&
                get(formikProps.errors, "arpu.value.min")
            )
          }
          helperText={
            get(formikProps.touched, "arpu.value.max") &&
            !!get(formikProps.values, "arpu.value.min") &&
            !!get(formikProps.values, "arpu.value.min") &&
            get(formikProps.errors, "arpu.value.min")
          }
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          formikProps={formikProps}
          name="arpu.value.max"
          disabled={!editState}
          onChange={handleTextChange}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter max ARPU"
        />
      </Grid>
    </Paper>
  );

  function handleChange(event) {
    const {
      target: { value, name },
    } = event;

    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
      // percentage: setPercentage(prevVal.percentage),
    }));

    function setValuesOfDropDown(values) {
      values.value = typeof value === "string" ? value.split(",") : value;
      values.mandatory = true;
      return values;
    }
  }

  function handleTextChange(event) {
    const {
      target: { value, name },
    } = event;
    let objectName = name.substring(0, name.indexOf("."));
    const name1 = name.split(".");

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      // percentage: setPercentage(prevVal.percentage),
    }));

    function setValues(val) {
      if (typeof name[name1] === "string") {
        val.mandatory = true;
        val.value = value;
      } else {
        set(val, name.substring(name.indexOf(".") + 1), value);
        val.mandatory = true;
      }

      return val;
    }
  }

  function setPercentage(percentage) {
    let p = 0;

    for (let key of Object.keys(formikProps.values)) {
      if (
        key === "age" ||
        key === "gender" ||
        key === "arpu" ||
        key === "audience_location" ||
        key === "languages"
      ) {
        if (formikProps.values[key].mandatory) {
          p = p + 1;
        }
      }
    }

    return {
      ...percentage,
      value: p,
    };
  }

  function renderingValue(value, array) {
    var arr = [];

    if (value.length > 0) {
      value.map((item) => arr.push(array.find((x) => x.value === item).label));
    }

    return arr;
  }
}
