import { Formik } from "formik";

import CampaignForm from "../create campaign components/CreateCampaignForm";

export default function Form({
  liveUsers,
  audiences,
  formData,
  takeUpOffer,
  setTakeUpOffer,
  brandDetails,
  setBrandDetails,
  audienceData,
  component,
  icon,
  categories,
  location,
  languages,
  rejectOffer,
  setRejectOffer,
}) {
  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <CampaignForm
          editState={false}
          categories={categories}
          location={location}
          languages={languages}
          icon={icon}
          liveUsers={liveUsers}
          formikProps={formikProps}
          audiences={audiences}
          component={component}
          takeUpOffer={takeUpOffer}
          setTakeUpOffer={setTakeUpOffer}
          brandDetails={brandDetails}
          setBrandDetails={setBrandDetails}
          audienceData={audienceData}
          rejectOffer={rejectOffer}
          setRejectOffer={setRejectOffer}
        />
      )}
    </Formik>
  );
}
