import { Fragment } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Avatar, Grid, Typography } from "@mui/material";

import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import BASE_URL from "../../../constants/baseURL";
import { HeadingEighteen, TextFourteenBlack } from "../../css components/Style";

const ActiveHeading = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`;

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  padding-right: 27px;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const ImageDiv = styled.img``;

const BrandName = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.4;
  text-transform: capitalize;
`;

const BrandImage = styled(Avatar)`
  height: 70px;
  width: 70px;
  position: absolute;
  top: 69px;
  left: 41px;
`;

export default function List({ campaigns, component, categorize }) {
  const navigate = useNavigate();

  function convertDate(d) {
    let date = new Date(d);

    return (
      date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    );
  }

  function handleRedirection(campaign) {
    if (component === "request") {
      navigate(
        pages.campaignRequestDetails.route +
          campaign.sender_campaignId +
          "?brandID=" +
          campaign.sender_brandId
      );
    }

    if (component === "superadmin") {
      navigate(
        pages.superAdminCampaignDetails.route +
          campaign.campaign_id +
          "?brandID=" +
          campaign.brand_id,
        { state: { categorize: categorize } }
      );
    }

    if (component === "accepted") {
      navigate(
        pages.campaignAcceptedDetails.route +
          campaign.campaign_id +
          "?brandID=" +
          campaign.brand_id
      );
    }

    if (component === "") {
      navigate(pages.campaignDetails.route + campaign.campaign_id);
    }
  }

  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  return (
    <Grid container spacing={3}>
      {campaigns.map((campaign) => (
        <Grid item xs={12}>
          <Paper>
            <Grid item alignSelf="center" paddingRight="59px">
              <div style={{ position: "relative" }}>
                <BrandImage
                  src={
                    BASE_URL.mox_api.url + campaign.brand.logo ||
                    "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png"
                  }
                />
                <ImageDiv
                  className="campaign-img"
                  src={
                    campaign.previewImage ||
                    "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png"
                  }
                  onError={onImageError}
                  alt="!"
                />
              </div>
            </Grid>

            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item>
                  {component !== "" && (
                    <BrandName>{campaign.brand.name}</BrandName>
                  )}
                  <HeadingEighteen>{campaign.campaign_name}</HeadingEighteen>
                </Grid>

                <Grid item xs />

                <Grid item display="flex">
                  {(component === "" || component === "superadmin") && (
                    <Fragment>
                      <svg
                        style={{ position: "relative", margin: "9px 9px" }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill={
                            campaign.status === "REQUESTED" ||
                            campaign.status === "CREATED"
                              ? "#FFBB0B"
                              : campaign.status === "OFFER_ACCEPTED" ||
                                campaign.status === "ACTIVE"
                              ? "#34B53A"
                              : "#FF3A29"
                          }
                        />
                      </svg>

                      <ActiveHeading
                        color={
                          campaign.status === "REQUESTED" ||
                          campaign.status === "CREATED"
                            ? "#FFBB0B"
                            : campaign.status === "OFFER_ACCEPTED" ||
                              campaign.status === "ACTIVE"
                            ? "#34B53A"
                            : "#FF3A29"
                        }
                        variant="button"
                      >
                        {campaign.status.replace(/_/g, " ")}
                      </ActiveHeading>
                    </Fragment>
                  )}
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={3}>
                  <TextFourteenBlack>Campaign Period</TextFourteenBlack>
                  <HeadingEighteen>
                    {convertDate(campaign.start_duration)}&nbsp; - &nbsp;
                    {convertDate(campaign.end_duration)}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Conversion Type</TextFourteenBlack>
                  <HeadingEighteen>{campaign.conversion_type}</HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Budget</TextFourteenBlack>
                  <HeadingEighteen>
                    {campaign.budget_min} - {campaign.budget_max}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>User Count</TextFourteenBlack>
                  <HeadingEighteen>
                    {campaign.userCount || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid
                  item
                  xs={2}
                  textAlign={"end"}
                  alignSelf={"end"}
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Link
                    style={{ cursor: "pointer", paddingRight: "0px" }}
                    onClick={() => handleRedirection(campaign)}
                  >
                    View
                  </Link>
                  <ArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
