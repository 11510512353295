import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import signIn from "./helper functions/signIn";
import signOut from "./helper functions/signOut";

export const signInMember = createAsyncThunk("member/signIn", signIn);

export const signOutMember = createAsyncThunk("member/signOutMember", signOut);

const memberInitialState = {
  authToken: "",
  userID: "",
  businessEntityID: "",
  pID: "",
  email: "",
  givenName: "",
  familyName: "",
  displayName: "",
  brands: [],
  dashboardState: "",
  activeBrand: {
    ID: "",
    about: "",
    brandName: "",
    logo: "",
    languages: [],
    locations: [],
    category: [],
    mau: "",
    onlineStore: "",
    website: "",
    socialMedia: {},
  },
};

export const memberSlice = createSlice({
  name: "member",
  initialState: memberInitialState,
  reducers: {
    setActiveBrand(state, { payload }) {
      state.activeBrand.ID = parseInt(payload.brand_id);
      state.activeBrand.about = payload.about;
      state.activeBrand.brandName = payload.brand_name;
      state.activeBrand.logo = payload.logo;
      state.activeBrand.languages = payload.languages;
      state.activeBrand.locations = payload.audience_location;
      state.activeBrand.category = payload.site_category;
      state.activeBrand.mau = payload.mau;
      state.activeBrand.onlineStore = payload.online_store;
      state.activeBrand.website = payload.website;
      state.activeBrand.socialMedia = payload.social_media_handles;
    },

    setBrands(state, { payload }) {
      state.brands.push({
        ID: parseInt(payload.brand_id),
        about: payload.about,
        brandName: payload.brand_name,
        logo: payload.logo,
        languages: payload.languages,
        locations: payload.audience_location,
        category: payload.site_category,
        mau: payload.mau,
        onlineStore: payload.online_store,
        website: payload.website,
        socialMedia: payload.social_media_handles,
      });
    },

    setDashboardState(state, { payload }) {
      state.dashboardState = payload;
    },

    deleteAccessToken: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return memberInitialState;
    },
  },

  extraReducers: {
    [signInMember.fulfilled]: (_, { payload }) => {
      return payload;
    },

    [signInMember.rejected]: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return { ...memberInitialState, authToken: "" };
    },

    [signOutMember.fulfilled]: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("brandType");
      localStorage.removeItem(
        "dashboardState" + sessionStorage.getItem("activeBrand")
      );
      sessionStorage.removeItem("activeBrand");

      sessionStorage.removeItem("businessEntityID");

      return memberInitialState;
    },
  },
});

export const {
  setDashboardState,
  deleteAccessToken,
  setBrands,
  setActiveBrand,
} = memberSlice.actions;

export default memberSlice.reducer;
