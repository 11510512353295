import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import LOGO from "../../../assets/placeholder.png";
import {
  ActiveTextEighteen,
  HeadingEighteen,
  TextFourteenBlack,
} from "../../css components/Style";

const BrandHeading = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.4;
`;

const RevenuCaption = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #4339f2;
`;

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
`;

const ImageDiv = styled.div`
  height: 115.8px;
  width: 276px;
`;

export default function ActiveCampaignList() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Grid item alignSelf="center" paddingRight="59px">
            <ImageDiv>
              <img
                style={{
                  height: "inherit",
                  width: "inherit",
                  borderRadius: "6px",
                }}
                src={LOGO}
                alt="!"
              />
            </ImageDiv>
          </Grid>

          <Grid item xs>
            <Grid container spacing={3}>
              <Grid item>
                <BrandHeading>Campaign Summary</BrandHeading>
                <HeadingEighteen>Summer Promo 15% Off</HeadingEighteen>
              </Grid>

              <Grid item xs />
              <Grid item alignSelf={"center"} display="flex">
                <svg
                  style={{ position: "relative", margin: "9px 9px" }}
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5" cy="5" r="5" fill="#5AC35F" />
                </svg>

                <ActiveTextEighteen variant="button">
                  In Progress
                </ActiveTextEighteen>
              </Grid>
              <Grid item xs={12} />

              <Grid item>
                <TextFourteenBlack>Campaign Period</TextFourteenBlack>
                <HeadingEighteen>01.01.23 - 31.03.23</HeadingEighteen>
              </Grid>

              <Grid item mr="15px" />
              <Grid item>
                <TextFourteenBlack>Conversion Type</TextFourteenBlack>
                <HeadingEighteen>18%</HeadingEighteen>
              </Grid>
              <Grid item mr="15px" />

              <Grid item>
                <TextFourteenBlack>Desired Volume</TextFourteenBlack>
                <HeadingEighteen>18%</HeadingEighteen>
              </Grid>

              <Grid item mr="15px" />
              <Grid item>
                <TextFourteenBlack>Total Revenue</TextFourteenBlack>
                <HeadingEighteen>$160,56M.88</HeadingEighteen>
              </Grid>
              <Grid item mr="15px" />

              <Grid item>
                <TextFourteenBlack>Total Payout </TextFourteenBlack>
                <RevenuCaption>$52,39M.99</RevenuCaption>
              </Grid>

              <Grid item xs />

              <Grid item alignSelf={"center"}>
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(pages.billingReport.route)}
                >
                  View
                </Link>
              </Grid>
              <Grid item xs />

              <Grid item alignSelf={"center"}>
                <Link style={{ cursor: "pointer" }}>All Billings</Link>
              </Grid>
              <Grid item xs />

              <Grid item alignSelf={"center"}>
                <Link style={{ cursor: "pointer" }}>Expand</Link>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
