import { Fragment } from "react";
import styled from "styled-components";
import { BiErrorCircle } from "react-icons/bi";
import { Grid, Typography as MuiTypography } from "@mui/material";

import { PrimaryButton } from "../css components/Button";

const Icon = styled(BiErrorCircle)`
  width: 100px;
  height: 100px;
  color: red;
`;

const Typography = styled(MuiTypography)`
  text-align: center;
`;

export default function InternetDown({ disableHomeButton }) {
  return (
    <Fragment>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Icon />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">No Internet Connection.</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Try connection network first.
          </Typography>
        </Grid>

        <Grid item>
          <PrimaryButton
            variant="contained"
            onClick={() => window.location.reload()}
          >
            Retry
          </PrimaryButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}
