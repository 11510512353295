import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useState, useEffect } from "react";

import FormSixth from "./PreviewImageForm";
import CampaignHeader from "./CampaignHeader";
import FormOne from "./CreateCampaignFirstForm";
import FormTwo from "./CreateCampaignSecondForm";
import { PageHeader } from "../../../components";
import FormThird from "./CreateCampaignThirdForm";
import CampaignLiveUser from "./CampaignLiveUser";
import FormFifth from "./CreateCampaignFifthForm";
import FormFourth from "./CreateCampaignFouthForm";
import BASE_URL from "../../../constants/baseURL";
import ExploreConnection from "./ExploreConnection";
import CampaignButtons from "./CreateCampaignButtons";
import AdminCampaignStatus from "./AdminCampaignStatus";
import TakeUpDialog from "../take up offer components/TakeUpDailog";
import BrandDetailsDialog from "../brand details components/BrandDetailsDialog";
import RejectOfferDialog from "../reject offer components/RejectOfferDialog";
import CampaignStatus from "./Status";
import Connections from "../superadmin campaign details component/ConnectionList";

export default function CampaignForm({
  categories,
  audiences,
  formikProps,
  editState,
  setEditState,
  component = "",
  takeUpOffer,
  setTakeUpOffer,
  liveUsers,
  brandDetails,
  setBrandDetails,
  audienceData,
  setAudienceCreated,
  icon,
  rejectOffer,
  setRejectOffer,
  connectionList,
  setAudienceData,
}) {
  const [brandIdentity, setBrandIdentity] = useState(false);
  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (formikProps.values.brandID) {
      if (parseInt(formikProps.values.brandID) !== parseInt(activeBrand.ID)) {
        setBrandIdentity(true);
      }
    }
  }, [formikProps.values.brandID, activeBrand.ID]);

  return (
    <Fragment>
      {!formikProps.values.campaignID ? (
        <Grid item xs={12}>
          <PageHeader
            heading={
              component !== "request"
                ? "My Campaigns"
                : "Request Campaign Details"
            }
            twoText={!formikProps.values.campaignID && true}
            firstText={"Create Campaign. "}
            secondText={"Start Converting."}
            buttons={[
              <CampaignButtons
                ID={formikProps.values.campaignID}
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
                component={component}
                brandIdentity={brandIdentity}
                setTakeUpOffer={setTakeUpOffer}
                liveUsers={liveUsers}
                setRejectOffer={setRejectOffer}
              />,
            ]}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CampaignHeader
            heading={
              component !== "request"
                ? component === "accepted"
                  ? "Accepted Campaign"
                  : "My Campaigns"
                : "Request Campaign Details"
            }
            brandName={
              component === ""
                ? activeBrand.brandName
                : brandDetails?.data?.brandName
            }
            iconImage={
              component === "" ? BASE_URL.mox_api.url + activeBrand.logo : icon
            }
            pageName={formikProps.values.campaign_name.value}
            buttons={[
              <CampaignButtons
                ID={formikProps.values.campaignID}
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
                component={component}
                brandIdentity={brandIdentity}
                setTakeUpOffer={setTakeUpOffer}
                setRejectOffer={setRejectOffer}
              />,
            ]}
          />
        </Grid>
      )}

      {component !== "" &&
        formikProps.values.campaignStatus !== "REQUESTED" && (
          <Grid item xs={12}>
            <CampaignStatus
              formikProps={formikProps}
              setTakeUpOffer={setTakeUpOffer}
            />
          </Grid>
        )}

      {component === "" && !!formikProps.values.campaignID && (
        <AdminCampaignStatus formikProps={formikProps} />
      )}

      {component === "" && <Connections connections={connectionList} />}

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            categories={categories}
            brandDetails={brandDetails}
            setBrandDetails={setBrandDetails}
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />

          <FormTwo
            setAudienceData={setAudienceData}
            formikProps={formikProps}
            audiences={audiences}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
            audienceData={audienceData}
            setAudienceCreated={setAudienceCreated}
          />

          {formikProps.values.campaignID &&
            component !== "request" &&
            component !== "accepted" && (
              <ExploreConnection formikProps={formikProps} />
            )}
        </Grid>

        <Grid item xs={6}>
          <FormThird
            brandIdentity={brandIdentity}
            formikProps={formikProps}
            editState={editState}
            component={component}
          />

          <FormFourth
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />

          {!brandIdentity && (
            <FormSixth
              formikProps={formikProps}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
            />
          )}

          {formikProps.values.campaignID && (
            <FormFifth
              formikProps={formikProps}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
            />
          )}

          {brandIdentity && !!formikProps.values.audienceID && (
            <CampaignLiveUser
              takeUpOffer={takeUpOffer}
              liveUsers={liveUsers}
              component={component}
            />
          )}
        </Grid>
      </Grid>

      <RejectOfferDialog reject={rejectOffer} setReject={setRejectOffer} />
      <TakeUpDialog
        takeUp={takeUpOffer}
        formikProps={formikProps}
        setTakeUp={setTakeUpOffer}
      />
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Fragment>
  );
}
