import styled from "styled-components/macro";
import { HighlightOff } from "@mui/icons-material";
import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton, Typography, Avatar } from "@mui/material";

import { colors } from "../../css components/Style";
import { TextField, AutocompleteMultiSelect, Label } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const ImageAvatar = styled(Avatar)`
  border: 1px solid #999999;
  height: 120px;
  width: 120px;
  border-radius: 50%;
`;

export default function FormDisplay({
  handleClose,
  formikProps,
  editState,
  categories,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <MainHeading>Brand Profile</MainHeading>
      </Grid>

      <Grid item xs={4} textAlign="end">
        <IconButton padding="0px" onClick={handleClose}>
          <HighlightOff />
        </IconButton>
      </Grid>

      <Grid item xs={6}>
        <Label title={"Display Picture"} />
      </Grid>

      <Grid item xs={6}>
        <Label title={formikProps.values.brandName} color={colors.info} />
      </Grid>

      <Grid item xs={6} textAlign="center" display="flex">
        <ImageAvatar src={formikProps.values.brandAvatar} />
      </Grid>

      <Grid item xs={6}>
        <Typography>{formikProps.values.about}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Label title={"Online Store"} />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Website"} />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="onlineStore"
          placeholder="Online Store"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.onlineStore && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://" +
                      formikProps.values.onlineStore.replace(
                        /^https?\:\/\//i,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="website"
          placeholder="Website"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.website && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://" +
                      formikProps.values.website.replace(/^https?\:\/\//i, "")
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label title="Monthly Active User" />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Location"} />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="monthlyActiveUsers"
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="audienceLocation"
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Language"} />
      </Grid>

      <Grid item xs={6}>
        <Label title="Business Category" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="audienceLanguages"
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="siteCategory"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={categories}
          placeholder="Select Category"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "siteCategory", "name")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Facebook</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Instagram</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.facebook"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.facebook && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://facebook.com/" +
                      formikProps.values.socialMedia.facebook.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.instagram && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://instagram.com/" +
                      formikProps.values.socialMedia.instagram.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Twitter</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Whatsapp</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.twitter && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://twitter.com/" +
                      formikProps.values.socialMedia.twitter.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.whatsapp && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://wa.me/" + formikProps.values.socialMedia.whatsapp
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  function linkOpen(val) {
    window.open(val, "_blank");
  }

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [event.target.name]: setValuesOfDropDown(prevVal.name),
    }));

    function setValuesOfDropDown(values) {
      values = typeof value === "string" ? value.split(",") : value;
      return values;
    }
  }
}
