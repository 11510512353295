import { Grid } from "@mui/material";

import { Fragment } from "react";

import { Label, Paper, TextField } from "../../../components";

export default function FormTwo({ formikProps, fields, editState }) {
  return (
    <Paper>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Grid item xs={10}>
            <Label title={field.field_label} />
          </Grid>

          <Grid item xs={10}>
            <TextField
              name={"fields." + [index] + "." + [field.field_name]}
              placeholder={"Enter " + field.field_label}
              formikProps={formikProps}
              disabled={!editState}
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>
        </Fragment>
      ))}
    </Paper>
  );
}
