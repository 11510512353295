import React from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Paper } from "../../../components";

const Heading = styled(Typography)`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 22px;
  margin-top: 8px;
  color: #fff;
`;

const SubHeading = styled(Typography)`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 67.02px;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 8px;
`;

const Text = styled(Typography)`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  color: #fff;
`;

const ContentItem = styled.div`
  margin: 10px;
`;

const Card = styled(Paper)`
  background: #4339f2;
  border-radius: 10px;
  height: 260px;
  width: 448px;
`;
const RevenueCard = styled(Paper)`
  background: #02a0fc;
  border-radius: 10px;
  height: 260px;
  width: 448px;
`;

const Payouts = styled(Paper)`
  background: #34b53a;
  border-radius: 10px;
  height: 260px;
  width: 448px;
`;

export default function BillingDetails() {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={4} style={{ justifyContent: "center" }}>
          <Grid item xs={6} sm={6} lg={4} md={4}>
            <Card>
              <ContentItem>
                <Heading>Account Balance</Heading>
                <SubHeading>$2,80M.000</SubHeading>
                <Text>Available for payout</Text>
              </ContentItem>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} lg={4} md={4}>
            <RevenueCard>
              <ContentItem>
                <Heading>Total Revenue</Heading>
                <SubHeading>$1,200,000</SubHeading>
                <Text>Expected today</Text>
              </ContentItem>
            </RevenueCard>
          </Grid>

          <Grid item xs={6} sm={6} lg={4} md={4}>
            <Payouts>
              <ContentItem>
                <Heading>Total Payouts</Heading>
                <SubHeading>$102,633.07</SubHeading>
                <Text>Expected today</Text>
              </ContentItem>
            </Payouts>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
