import React from "react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import SortBy from "./Sort";
import Search from "./Search";
import { PageHeader } from "../../components";

import pages from "../../constants/pages";
import List from "./active campaign component/List";
import ActiveCampaignList from "./active campaign component/ActiveCampaignList";
import BillingDetails from "./billing details component/BillingDetails";

const GenerateReport = styled(Button)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 20px;
`;

export default function Billing() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeader pageName={"My Billings"} />
      </Grid>

      <Grid item xs />
      <Search />

      <Grid item />

      <SortBy />
      <Grid item />

      <Grid item alignSelf="center">
        <GenerateReport
          variant="contained"
          size="large"
          onClick={() => navigate(pages.billingReport.route)}
        >
          Generate Report
        </GenerateReport>
      </Grid>
      <BillingDetails />
      <Grid item xs />

      <Grid item xs={12}>
        <ActiveCampaignList />
        <List />
      </Grid>
    </Grid>
  );
}
