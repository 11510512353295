import styled from "styled-components/macro";
import { Grid, MenuItem, Typography } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import { Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export default function FormFourth({ formikProps, editState, component }) {
  return (
    <Paper style={{ marginTop: component !== "request" && "25px" }}>
      <Grid item xs={12}>
        <MainHeading>Payout</MainHeading>
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Conversion Type"
          formikProps={formikProps}
          required={true}
          select={true}
          name={"conversion_type.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          select
          formikProps={formikProps}
          name="conversion_type.value"
          onBlur={() => {
            formikProps.setTouched({
              "conversion_type.value": true,
            });
          }}
          inputProps={{
            "aria-label": "Without label",
            IconComponent: () => editState && null,
          }}
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color:
                formikProps.values.conversion_type.value === "" && "#bababacf",
            },
          }}
        >
          <Menu disabled selected value="">
            Select Conversion Type
          </Menu>
          <Menu value="Revenue Share">Revenue Share</Menu>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Label title="Payout Percentage" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          select
          formikProps={formikProps}
          name="payout_percentage.value"
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          inputProps={{
            "aria-label": "Without label",
            IconComponent: () =>
              !editState ? null : (
                <ArrowDropDown
                  style={{ marginRight: "8px", cursor: "pointer" }}
                />
              ),
          }}
          InputProps={{
            style: {
              color:
                formikProps.values.payout_percentage.value === "" &&
                "#bababacf",
            },
          }}
        >
          <Menu selected disabled value="">
            Select payout percentage
          </Menu>
          <Menu value="30% of Sales Revenue (Net of Tax)">
            30% of Sales Revenue (Net of Tax)
          </Menu>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Label title="Payout Terms" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          formikProps={formikProps}
          name="payout_terms.value"
          inputProps={{
            "aria-label": "Without label",
            IconComponent: () =>
              !editState ? null : (
                <ArrowDropDown
                  style={{ marginRight: "8px", cursor: "pointer" }}
                />
              ),
          }}
          select
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color:
                formikProps.values.payout_terms.value === "" && "#bababacf",
            },
          }}
        >
          <Menu selected disabled value="">
            Select payout terms
          </Menu>
          <Menu value="60 days from campaign end date">
            60 days from campaign end date
          </Menu>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Label title="Trial Period" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          formikProps={formikProps}
          name="trail_period.value"
          inputProps={{
            "aria-label": "Without label",
            IconComponent: () =>
              !editState ? null : (
                <ArrowDropDown
                  style={{ marginRight: "8px", cursor: "pointer" }}
                />
              ),
          }}
          select
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color:
                formikProps.values.trail_period.value === "" && "#bababacf",
            },
          }}
        >
          <Menu selected disabled value="">
            Select trial period
          </Menu>
          <Menu value="7 days from launch">7 days from launch</Menu>
        </TextField>
      </Grid>
    </Paper>
  );
}
