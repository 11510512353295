import { Typography } from "@mui/material";
import styled from "styled-components/macro";

export const colors = {
  success: "#34b53a",
  warning: "#FFBB0B",
  error: "#FF3A29",
  info: "#02A0FC",
};

export const HeadingEighteen = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  overflow-wrap: break-word;
`;

export const ActiveTextEighteen = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  overflow-wrap: break-word;
`;

export const TextFourteenBlack = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
  overflow-wrap: break-word;
`;

export const TextFifteenYellow = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  overflow-wrap: break-word;
`;

export const TextDelete = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ff0000;
  overflow-wrap: break-word;
`;

export const NoListTypography = styled(Typography)`
  font-weight: 500;
  font-size: 30px;
  display: flex;
  justify-content: center;
  overflow-wrap: break-word;
`;
