import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import {
  validationSchema,
  objectFromFormData,
} from "../brand details components/brandDetailObject";
import FormDisplay from "../brand details components/FormDisplay";
import { setActiveBrand, setBrands } from "../../../redux/slices/memberSlice";

export default function Form({
  formData,
  categories,
  location,
  languages,
  component,
}) {
  const axios = useAxios();

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const memberDetails = useSelector((state) => state.memberDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/user/brand",
          method: "POST",
          data: objectFromFormData(formData, memberDetails),
        })
          .then((res) => {
            if (res.success) {
              dispatch(
                alert({
                  message: "Brand created successfully!",
                  type: "success",
                })
              );
              sessionStorage.setItem("activeBrand", res.data.brand_id);
              localStorage.setItem(
                "dashboardState" + res.data.brand_id,
                "monetization"
              );

              dispatch(setActiveBrand(res.data));
              dispatch(setBrands(res.data));
              navigate(pages.brandDetails.route);

              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form style={{ padding: "20px" }} onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            categories={categories}
            location={location}
            component={component}
            languages={languages}
          />
        </form>
      )}
    </Formik>
  );
}
