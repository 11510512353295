import React from "react";
import { Grid, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { capitalCase } from "change-case-all";

import {
  ActiveTextEighteen,
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { Paper } from "../../../components";
import { useAxios, useAlert } from "../../../hooks";

export default function List({ usersList, setUserStatus }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Grid container spacing={3}>
      {usersList.map((user) => (
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={2}>
              <TextFourteenBlack>Given Name</TextFourteenBlack>
              <HeadingEighteen>
                {user.given_name.replace(/_/g, " ") || "-"}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Family Name</TextFourteenBlack>
              <HeadingEighteen>{user.family_name || "-"}</HeadingEighteen>
            </Grid>

            <Grid item xs={3}>
              <TextFourteenBlack>Email</TextFourteenBlack>
              <HeadingEighteen
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                {user.email?.length > 25
                  ? user.email?.substr(0, 25) + "..."
                  : user.email || "-"}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2}>
              <TextFourteenBlack>Role</TextFourteenBlack>
              <HeadingEighteen>
                {(user.role_name &&
                  capitalCase(user.role_name.replace(/_/g, " "))) ||
                  "-"}
              </HeadingEighteen>
            </Grid>

            <Grid
              item
              xs={3}
              alignSelf={"end"}
              display="flex"
              justifyContent={"center"}
              sx={{
                cursor: "pointer",
              }}
            >
              <ActiveTextEighteen
                onClick={() => handleAction(user, "VERIFIED")}
                color={colors.success}
                fontWeight={400}
              >
                Approve
              </ActiveTextEighteen>{" "}
              <Divider
                orientation="vertical"
                style={{
                  height: "36px",
                  color: "black",
                  width: "20px",
                  marginRight: "25px",
                }}
                flexItem
              />{" "}
              <ActiveTextEighteen
                color={colors.error}
                onClick={() => handleAction(user, "REJECTED")}
                fontWeight={400}
              >
                Reject
              </ActiveTextEighteen>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  function handleAction(user, status) {
    axios({
      url: "/user/accountAction",
      method: "POST",
      data: {
        email: user.email,
        user_id: user.user_id.toString(),
        status: status,
      },
    }).then((response) => {
      if (response.success) {
        setUserStatus(user.user_id);
        dispatch(
          alert({
            message: response.data,
            type: "success",
          })
        );
      }
    });
  }
}
