import * as Yup from "yup";
import _ from "lodash";
import BASE_URL from "../../../constants/baseURL";
import toInternationalFormat from "../../../utils/toInternationalFormat";

export const brandDetailsObject = {
  businessEntityId: "",
  brandName: "",
  website: "",
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandLogo: "",
  about: "",
  onlineStore: "",
  siteCategory: [],
  monthlyActiveUsers: "",
};

export function objectFromFormData(formData, details) {
  return {
    business_entity_id: details.businessEntityID,
    brand_name: formData.brandName,
    website: formData.website,
    audience_location: formData.audienceLocation,
    socialMedia: formData.socialMedia,
    brandLogo: formData.brandLogo,
  };
}

export function objectFromBrandResponse(response) {
  let medias = response?.social_media_handles;
  let languages = [];
  for (let lang of response.languages) {
    languages.push(lang.toUpperCase());
  }
  return {
    brandAvatar: BASE_URL.mox_api.url + response.logo,
    displayName: response.display_name,
    familyName: response.family_name,
    givenName: response.given_name,
    email: response.email,
    role: response.role,
    password: response.password,
    brandID: response?.brand_id,
    businessEntityId: response.business_entity_id,
    brandName: response.brand_name,
    website: response.website,
    audienceLocation: response?.audience_location?.map((loc) => loc).join(","),
    audienceLanguages: languages,
    siteCategory: response.site_category,
    socialMedia: {
      facebook: medias.facebook,
      instagram: medias.instagram,
      twitter: medias.twitter,
      whatsapp: medias.whatsapp,
    },
    brandLogo: response.logo,
    about: response.about,
    onlineStore: response.online_store,
    monthlyActiveUsers: response.mau && toInternationalFormat(response.mau),
  };
}

export const validationSchema = Yup.object().shape({});
