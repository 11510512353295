import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import {
  HeadingEighteen,
  TextFourteenBlack,
  TextFifteenYellow,
} from "../../css components/Style";
import BASE_URL from "../../../constants/baseURL";
import { Paper, TextAvatar } from "../../../components";
import { useNavigate } from "react-router-dom";
import pages from "../../../constants/pages";

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  padding-right: 27px;
  cursor: pointer;
  padding-right: 0px;
`;

const ImageDiv = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 6px;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

export default function UserList({ usersData }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {usersData.users.map((user) => (
        <Grid item xs={12} key={user.user_id}>
          <Paper>
            <Grid item alignSelf="center" paddingRight="59px">
              {user.logo ? (
                <ImageDiv
                  src={BASE_URL.mox_api.url + usersData.brand.brand_logo}
                  alt="!"
                />
              ) : (
                <TextAvatar
                  size={{ height: "120px", width: "120px", fontSize: "48px" }}
                  userName={user.given_name}
                />
              )}
            </Grid>

            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextFourteenBlack> User Name </TextFourteenBlack>
                  <HeadingEighteen>{user.given_name}</HeadingEighteen>
                </Grid>

                <Grid item xs={4}>
                  <TextFourteenBlack> Brand </TextFourteenBlack>
                  <HeadingEighteen>
                    {usersData.brand.brand_name}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={4} justifyContent={"end"} display="flex">
                  <svg
                    style={{ position: "relative", margin: "9px 9px" }}
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="5"
                      fill={
                        user.account_status === 0
                          ? "#FFBB0B"
                          : user.account_status === 1
                          ? "#5AC35F"
                          : "#FF460B"
                      }
                    />
                  </svg>
                  <TextFifteenYellow
                    color={
                      user.account_status === 0
                        ? "#FFBB0B"
                        : user.account_status === 1
                        ? "#5AC35F"
                        : "#FF460B"
                    }
                  >
                    {user.account_status === 0 && "Unverified"}
                    {user.account_status === 1 && "Accepted"}
                    {user.account_status === 2 && "Blocked"}
                    {user.account_status === 3 && "Rejected"}
                    {user.account_status === 4 && "Suspended"}
                    {user.account_status === 5 && "Deleted"}
                  </TextFifteenYellow>
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={4}>
                  <TextFourteenBlack>Role</TextFourteenBlack>
                  <HeadingEighteen>{user.brand_user_role}</HeadingEighteen>
                </Grid>

                <Grid item xs={4}>
                  <TextFourteenBlack>Email</TextFourteenBlack>
                  <HeadingEighteen>{user.email}</HeadingEighteen>
                </Grid>

                <Grid
                  item
                  xs={4}
                  alignSelf={"end"}
                  justifyContent="end"
                  display="flex"
                >
                  <Link
                    onClick={() =>
                      navigate(pages.userDetails.route + user.user_id)
                    }
                  >
                    Manage
                  </Link>
                  <ArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
