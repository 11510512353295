import * as Yup from "yup";
import BASE_URL from "../../../constants/baseURL";
import toInternationalFormat from "../../../utils/toInternationalFormat";

export const brandDetailsObject = {
  brandID: "",
  businessEntityId: "",
  brandName: "",
  website: "",
  profile: "",
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandAvatar: "",
  avatar: "",
  about: "",
  onlineStore: "",
  siteCategory: "",
  monthlyActiveUsers: "",
  advertiserId: "",
  publisher: {
    siteId: "",
    zoneId: [],
    isPublisher: "",
    networkId: "",
  },
};

export function objectFromFormData(formData, memberDetails) {
  const data = new FormData();
  let smh = {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  };

  let fb = formData.socialMedia?.facebook?.lastIndexOf("/");
  let ig = formData.socialMedia?.instagram?.lastIndexOf("/");
  let tw = formData.socialMedia?.twitter?.lastIndexOf("/");
  let wh = formData.socialMedia?.whatsapp?.lastIndexOf("/");

  smh.facebook = formData.socialMedia?.facebook?.substring(fb + 1);
  smh.instagram = formData.socialMedia?.instagram?.substring(ig + 1);
  smh.twitter = formData.socialMedia?.twitter?.substring(tw + 1);
  smh.whatsapp = formData.socialMedia?.whatsapp?.substring(wh + 1);

  data.append("business_entity_id", memberDetails.businessEntityID);
  data.append("brand_name", formData.brandName);
  data.append("website", formData.website);
  data.append("audience_location", JSON.stringify(formData.audienceLocation));
  data.append("brandLogo", formData.brandAvatar);
  Boolean(data.append("languages", JSON.stringify(formData.audienceLanguages)));
  Boolean(data.append("social_media_handles", JSON.stringify(smh)));
  Boolean(data.append("about", formData.about));
  Boolean(
    data.append("online_store", formData.onlineStore.replace(/[,]/g, ""))
  );
  Boolean(data.append("mau", formData.monthlyActiveUsers.replace(/[,]/g, "")));
  Boolean(data.append("site_category", JSON.stringify(formData.siteCategory)));

  return data;
}

export function objectFromResponse(response, component = "") {
  let data = {
    profile:
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_user_placeholder.jpg",
    displayName: response.display_name,
    familyName: response.family_name,
    givenName: response.given_name,
    email: response.email,
    role: response.role_name?.replace("_", " "),
    password: response.password,
    brandID: response?.brand_id,
    advertiserId: response.advertiser_id,
    publisher: {
      siteId:
        Boolean(response.publisher.is_publisher) && response.publisher.site_id,
      zoneId:
        Boolean(response.publisher.is_publisher) && response.publisher.zone_id,
      isPublisher:
        Boolean(response.publisher.is_publisher) &&
        response.publisher.is_publisher,
      networkId:
        Boolean(response.publisher.is_publisher) &&
        response.publisher.network_id,
    },
  };

  let medias = response?.social_media_handles;
  let fb = medias.facebook?.replace("@", "");
  let ig = medias.instagram?.replace("@", "");
  let tw = medias.twitter?.replace("@", "");

  if (component === "new") {
    data = {
      ...data,
      fileName: "",
      brandAvatar: "",
      avatar: "",
      businessEntityId: response.business_entity_id,
      brandName: "",
      website: "",
      audienceLocation: [],
      audienceLanguages: [],
      siteCategory: [],
      socialMedia: {
        facebook: "",
        instagram: "",
        twitter: "",
        whatsapp: "",
      },
      about: "",
      onlineStore: "",
      monthlyActiveUsers: "",
    };
  } else {
    data = {
      ...data,
      brandAvatar: BASE_URL.mox_api.url + response.logo,
      avatar: BASE_URL.mox_api.url + response.logo,
      fileName: response.logo?.replace(/^.*[\\\/]/, ""),
      brandID: response?.brand_id,
      businessEntityId: response.business_entity_id,
      brandName: response.brand_name,
      website: response.website,
      audienceLocation: response?.audience_location,
      audienceLanguages: response?.languages,
      siteCategory: response.site_category,
      socialMedia: {
        facebook: fb,
        instagram: ig,
        twitter: tw,
        whatsapp: medias.whatsapp,
      },
      about: response.about,
      onlineStore: response.online_store,
      monthlyActiveUsers: response.mau && toInternationalFormat(response.mau),
      advertiserId: response.advertiser_id,
      publisher: {
        siteId:
          Boolean(response.publisher.is_publisher) &&
          response.publisher.site_id,
        zoneId:
          Boolean(response.publisher.is_publisher) &&
          response.publisher.zone_id,
        isPublisher:
          Boolean(response.publisher.is_publisher) &&
          response.publisher.is_publisher,
        networkId:
          Boolean(response.publisher.is_publisher) &&
          response.publisher.network_id,
      },
    };
  }
  return data;
}

export const validationSchema = Yup.object().shape({
  brandName: Yup.string().required("Brand name is required."),
  audienceLocation: Yup.array().required("Audience location is required."),
  audienceLanguages: Yup.array().required("Audience language is required."),
  brandAvatar: Yup.mixed().required("Brand Logo is required."),
  about: Yup.string().required("About is required."),
  siteCategory: Yup.array().required("Category is required."),
  socialMedia: Yup.object().shape({
    facebook: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    instagram: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    twitter: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    whatsapp: Yup.string(),
  }),
  website: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
    "Enter correct url!"
  ),
  onlineStore: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
    "Enter correct url!"
  ),
});
