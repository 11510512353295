/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";

import Billing from "../../pages/billing components/Billing";
import UsersList from "../../pages/users components/UsersList";
import BrandDetails from "../../pages/brand components/BrandDetails";
import Connection from "../../pages/connection components/Connection";
import AudienceList from "../../pages/audience components/AudienceList";

import UserDetails from "../../pages/superAdmin user components/UsersDetails";
import ConnectionDetails from "../../pages/connection components/ConnectionDetails";
import AudienceDetails from "../../pages/audience components/AudienceDetails";
import SuperAdminUsers from "../../pages/superAdmin user components/UserList";
import SuperAdminBrand from "../../pages/superAdmin brand components/BrandList";
import ChangePassword from "../../pages/change password components/ChangePassword";

import Campaign from "../../pages/campaign components/campaign list parent components/Campaign";
import GenrateReport from "../../pages/billing components/genrate report component/GenrateReport";
import CampaignDP from "../../pages/campaign components/campaign list parent components/CampaignDP";
import BillingDetails from "../../pages/billing components/billing details component/BillingDetails";
import CampaignDetails from "../../pages/campaign components/create campaign components/CampaignDetails";
import DpOnBoardingDetails from "../../pages/superAdmin dp onboarding components/OnboardingDetails";
import OnBoardedConnectionList from "../../pages/superAdmin dp onboarding components/OnboardedConnectionList";
import CampaignRequestDetails from "../../pages/campaign components/campaign request components/CampaignRequestDetails";
import SuperAdminBrandCampaignList from "../../pages/campaign components/campaign list parent components/SuperAdminBrandCampaignList";
import SuperadminCampaignDetails from "../../pages/campaign components/superadmin campaign details component/SuperAdminCampaignDetails";
import SuperAdminAllCampaigns from "../../pages/campaign components/campaign list parent components/SuperAdminAllCampaigns";
import NewBrand from "../../pages/brand components/NewBrand";

// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

//Main Module Components

const dashboardLayoutRoutes = {
  brandDetailsRoute: {
    ID: pages.brandDetails.ID,
    path: pages.brandDetails.route,
    element: BrandDetails,
  },

  createCampaignRoute: {
    ID: pages.createCampaign.ID,
    path: pages.createCampaign.route,
    element: CampaignDetails,
  },

  myCampaignRoute: {
    ID: pages.myCampaign.ID,
    path: pages.myCampaign.route,
    element: Campaign,
  },

  myBillingRoute: {
    ID: pages.myBilling.ID,
    path: pages.myBilling.route,
    element: Billing,
  },

  billingReportRoute: {
    ID: pages.billingReport.ID,
    path: pages.billingReport.route,
    element: GenrateReport,
  },

  billingDetailsRoute: {
    ID: pages.billingDetails.ID,
    path: pages.billingDetails.route,
    element: BillingDetails,
  },

  campaignRequestRoute: {
    ID: pages.campaignRequest.ID,
    path: pages.campaignRequest.route,
    element: CampaignDP,
  },

  campaignDetailsRoute: {
    ID: pages.campaignDetails.ID,
    path: pages.campaignDetails.route + ":campaignID",
    element: CampaignDetails,
  },

  campaignRequestDetailsRoute: {
    ID: pages.campaignRequestDetails.ID,
    path: pages.campaignRequestDetails.route + ":campaignID",
    element: CampaignRequestDetails,
  },

  campaignAcceptedDetailsRoute: {
    ID: pages.campaignAcceptedDetails.ID,
    path: pages.campaignAcceptedDetails.route + ":campaignID",
    element: CampaignRequestDetails,
  },

  audienceListRoute: {
    ID: pages.audienceList.ID,
    path: pages.audienceList.route,
    element: AudienceList,
  },

  createAudienceRoute: {
    ID: pages.createAudience.ID,
    path: pages.createAudience.route,
    element: AudienceDetails,
  },

  audienceDetailsRoute: {
    ID: pages.audienceDetails.ID,
    path: pages.audienceDetails.route + ":audienceID",
    element: AudienceDetails,
  },

  exploreConnectionRoute: {
    ID: pages.exploreConnections.ID,
    path: pages.exploreConnections.route,
    element: Connection,
  },

  connectionDetailsRoute: {
    ID: pages.connectionDetails.ID,
    path: pages.connectionDetails.route + ":connectionID",
    element: ConnectionDetails,
  },

  changePasswordRoute: {
    ID: pages.changePassword.ID,
    path: pages.changePassword.route,
    element: ChangePassword,
  },

  // superadmin routes

  brandListRoute: {
    ID: pages.brandsList.ID,
    path: pages.brandsList.route,
    element: SuperAdminBrand,
  },

  userDetailsRoute: {
    ID: pages.userDetails.ID,
    path: pages.userDetails.route + ":userID",
    element: UserDetails,
  },

  userCreateRoute: {
    ID: pages.createUser.ID,
    path: pages.createUser.route,
    element: UserDetails,
  },

  userListRoute: {
    ID: pages.usersList.ID,
    path: pages.usersList.route + ":brandID",
    element: SuperAdminUsers,
  },

  accountVerificationRoute: {
    ID: pages.accountVerification.ID,
    path: pages.accountVerification.route,
    element: UsersList,
  },

  superadminBrandCampaignsListRoute: {
    ID: pages.superAdminBrandCampaigns.ID,
    path: pages.superAdminBrandCampaigns.route + ":brandID",
    element: SuperAdminBrandCampaignList,
  },

  superadminBrandCampaignsDetailsRoute: {
    ID: pages.superAdminCampaignDetails.ID,
    path: pages.superAdminCampaignDetails.route + ":campaignID",
    element: SuperadminCampaignDetails,
  },

  superadminAllCampaignsRoute: {
    ID: pages.superAdminAllCampaigns.ID,
    path: pages.superAdminAllCampaigns.route,
    element: SuperAdminAllCampaigns,
  },

  superadminDPOnBoardingDetailsRoute: {
    ID: pages.dpOnBoardingDetails.ID,
    path: pages.dpOnBoardingDetails.route + ":connectionID",
    element: DpOnBoardingDetails,
  },

  superadminDPOnBoardingCreateRoute: {
    ID: pages.dpOnBoarding.ID,
    path: pages.dpOnBoarding.route,
    element: DpOnBoardingDetails,
  },

  superadminDPOnBoardingListRoute: {
    ID: pages.dpOnBoardingList.ID,
    path: pages.dpOnBoardingList.route,
    element: OnBoardedConnectionList,
  },

  newBrandCreateRoute: {
    ID: pages.newBrandCreate.ID,
    path: pages.newBrandCreate.route,
    element: NewBrand,
  },
};

export default dashboardLayoutRoutes;
