import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "../../components";
import Form from "./create audience components/Form";
import {
  audienceObject,
  objectFromResponse,
} from "./create audience components/audienceObjects";
import { useAxios, useComponent } from "../../hooks";

export default function AudienceDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const audienceID = params.audienceID || "";
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(audienceObject);
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const [percentageSliderValue, setPercentageSliderValue] = useState(-1);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/brand/getAudienceFieldsInfo/",
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        for (let field of response.data) {
          setFormData((prev) => ({
            ...prev,
            [field.field_name]: setFieldValue(prev[field.field_name]),
          }));

          function setFieldValue(fields) {
            if (fields !== undefined) {
              fields.dpCount = field.dp_count;
              return fields;
            }
          }
        }

        loader.apiComplete(1);
      } else {
        loader.apiComplete(1);
      }
    });

    if (!!audienceID) {
      setEditState(false);

      axios({
        url: "/brand/getDetailsOfAudience/" + audienceID,
      }).then((response) => {
        if (response.success) {
          setFormData(objectFromResponse(response.data));

          response.data.fields.filter((items, index) => {
            if (items.hasOwnProperty("percentage")) {
              setPercentageSliderValue(items["percentage"]);
            }
          });
        }
        loader.apiComplete(1);
      });
    } else {
      loader.stop();
    }
  }, [axios, loader, audienceID]);

  return (
    <Loader>
      <Form
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        categories={categories}
        location={location}
        languages={languages}
        percentageSliderValue={percentageSliderValue}
        setPercentageSliderValue={setPercentageSliderValue}
      />
    </Loader>
  );
}
