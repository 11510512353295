import * as Yup from "yup";

export const audienceObject = {
  ID: "",
  brandID: "",
  minimumAge: "",
  isEditable: true,

  audience_group: {
    value: "",
    mandatory: false,
  },

  age: {
    value: { min: "", max: "" },
    mandatory: false,
  },

  gender: {
    value: [],
    mandatory: false,
  },

  approx_mau: {
    value: "",
    mandatory: false,
  },

  arpu: {
    value: { min: "", max: "" },
    mandatory: false,
  },

  audience_location: {
    value: [],
    mandatory: false,
  },

  languages: {
    value: [],
    mandatory: false,
  },
  percentage: {
    value: 0,
  },
};

export function objectFromResponse(response) {
  let data = {};
  data = {
    ...audienceObject,
  };

  for (let key of response.fields) {
    if (Array.isArray(Object.values(key)[0])) {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0].length > 0 ? Object.values(key)[0] : [],
        mandatory: Object.values(key)[1] || false,
      };
    }

    if (typeof Object.values(key)[0] === "string") {
      data[Object.keys(key)[0]] = {
        value: !!Object.values(key)[0] ? Object.values(key)[0] : "",
        mandatory: Object.values(key)[1] || false,
      };
    }

    if (
      typeof Object.values(key)[0] === "object" &&
      !Array.isArray(Object.values(key)[0])
    ) {
      data[Object.keys(key)[0]] = {
        value: Object.keys(key)[0].length > 0 ? Object.values(key)[0] : {},
        mandatory: Object.values(key)[1] || false,
      };
    }

    if (typeof Object.values(key)[0] === "number") {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] !== 0 ? Object.values(key)[0] : 0,
        mandatory: Object.values(key)[1] || false,
      };
    }
  }

  return {
    ...data,
    ID: response.audience_id,
    brandID: response.brand_id,
    isEditable: response.is_editable,
  };
}

export function objectFromFormData(formData, details) {
  return {
    brand_id: parseInt(details.activeBrand.ID),
    fields: [
      {
        audience_group: formData.audience_group,
      },
      {
        age: formData.age,
      },
      {
        gender: formData.gender,
      },
      {
        arpu: formData.arpu,
      },
      {
        audience_location: formData.audience_location,
      },
      {
        languages: formData.languages,
      },
      {
        percentage: formData.percentage,
      },
    ],
  };
}

export const validationSchema = Yup.object().shape({
  audience_group: Yup.object().shape({
    value: Yup.string()
      .min(3, "Greater than 3 letters")
      .required("Audience Group is required"),
  }),

  age: Yup.object().shape({
    value: Yup.object().shape({
      min: Yup.number().lessThan(Yup.ref("max"), "Max age should be greater"),
      max: Yup.number()
        .nullable()
        .moreThan(Yup.ref("min"), "Max age should be greater"),
    }),
  }),

  gender: Yup.object().shape({
    value: Yup.array(),
  }),

  approx_mau: Yup.object().shape({
    value: Yup.string(),
  }),

  arpu: Yup.object().shape({
    value: Yup.object().shape({
      min: Yup.number().lessThan(Yup.ref("max"), "Max arpu should be greater"),
      max: Yup.number()
        .nullable()
        .moreThan(Yup.ref("min"), "Max arpu should be greater"),
    }),
  }),

  audience_location: Yup.object().shape({
    value: Yup.array(),
  }),

  languages: Yup.object().shape({
    value: Yup.array(),
  }),
});
