import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import pages from "../../../constants/pages";
import CampaignForm from "./CreateCampaignForm";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./campaignObject";

export default function Form({
  audienceData,
  categories,
  connectionList,
  audiences,
  formData,
  editState,
  setEditState,
  setAudienceData,
  setAudienceCreated,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activeBrand } = useSelector((state) => state.memberDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/user/brand/campaign/";
        let method = "POST";
        if (!!formData.campaignID) {
          url = "/user/brand/campaign/" + formData.campaignID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData, activeBrand),
        })
          .then((res) => {
            if (res.success) {
              if (
                audienceData.ID !== formData.audienceID &&
                formData.audienceID
              ) {
                axios({
                  url: "/brand/getDetailsOfAudience/" + formData.audienceID,
                }).then(async (res) => {
                  if (res.success) {
                    let data = {};

                    for await (let key of res.data.fields) {
                      data[Object.keys(key)[0]] = {
                        value: Object.values(key)[0],
                        mandatory: Object.values(key)[1],
                      };
                    }

                    data = {
                      ...data,
                      ID: res.data.audience_id,
                      brandID: res.data.brand_id,
                    };

                    await setAudienceData(data);
                  }
                });
              }
              if (!!formData.campaignID) {
                dispatch(
                  alert({
                    message: "Campaign updated Successfully!",
                    type: "success",
                  })
                );

                setEditState(false);
              } else {
                dispatch(
                  alert({
                    message: "Campaign Created Successfully!",
                    type: "success",
                  })
                );

                navigate(
                  pages.exploreConnections.route +
                    "?campaignID=" +
                    res.data.campaign_id
                );
              }
            }

            setSubmitting(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <CampaignForm
          connectionList={connectionList}
          setAudienceData={setAudienceData}
          audienceData={audienceData}
          categories={categories}
          formikProps={formikProps}
          audiences={audiences}
          editState={editState}
          setEditState={setEditState}
          setAudienceCreated={setAudienceCreated}
        />
      )}
    </Formik>
  );
}
