import { Fragment } from "react";
import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import { PrimaryButton } from "../../css components/Button";

export default function FormDisplay({
  formikProps,
  categories,
  location,
  languages,
}) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"My Profile"}
          twoText={true}
          firstText={"Welcome. "}
          secondText={" Let's setup your profile."}
          fontSize="25px"
          buttons={[
            <PrimaryButton
              variant="contained"
              size="large"
              onClick={formikProps.handleSubmit}
              disabled={!formikProps.isValid}
            >
              Save
            </PrimaryButton>,
          ]}
        />
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            formikProps={formikProps}
            categories={categories}
            location={location}
            languages={languages}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTwo
            formikProps={formikProps}
            categories={categories}
            location={location}
            languages={languages}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
