import { Fragment } from "react";
import styled from "styled-components/macro";
import { TbExternalLink } from "react-icons/tb";
import { Grid, Typography, IconButton } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import {
  Paper,
  TextField,
  Label,
  AutocompleteMultiSelect,
} from "../../../components";
import BrandDetailsDialog from "../brand details components/BrandDetailsDialog";
import BASE_URL from "../../../constants/baseURL";
import { TextFourteenBlack } from "../../css components/Style";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const ImageDiv = styled.div`
  width: 286px;
  height: 120px;
  border: 1px solid #d6cbcb;
`;

export default function FormOne({
  categories,
  formikProps,
  editState,
  brandIdentity,
  component,
  brandDetails,
  setBrandDetails,
}) {
  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  function linkOpen(val) {
    window.open(val, "_blank");
  }
  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>
          {!brandIdentity ? "Campaign Details" : "Campaign Setup"}
        </MainHeading>
      </Grid>

      {formikProps.values.campaignID && (
        <Fragment>
          <Grid item xs={5}>
            <ImageDiv>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "fill",
                  borderRadius: "6px",
                }}
                src={BASE_URL.mox_api.url + formikProps.values.previewImage}
                alt="!"
                onError={onImageError}
              />
            </ImageDiv>
          </Grid>
        </Fragment>
      )}

      {(component === "request" ||
        component === "superadmin" ||
        component === "accepted") && (
        <Fragment>
          <Grid item xs={10}>
            <Label
              title="Brand Name"
              buttons={[
                <Typography
                  variant="button"
                  style={{
                    color: "#02A0FC",
                    marginTop: "14px",
                    font: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setBrandDetails((prevVal) => ({
                      ...prevVal,
                      state: true,
                    }))
                  }
                >
                  Brand Info
                </Typography>,
              ]}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              name="brand_name"
              placeholder="Brand Name"
              value={brandDetails?.data?.brandName}
              disabled={!editState}
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Label
          title="Campaign Name"
          formikProps={formikProps}
          required={true}
          name={"campaign_name.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="campaign_name.value"
          placeholder="Campaign Name"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="About Campaign" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="about.value"
          placeholder="About Campaign"
          multiline
          rows={5}
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title={"Campaign Destination URL"}
          formikProps={formikProps}
          required={true}
          name={"destination_url.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="destination_url.value"
          formikProps={formikProps}
          placeholder="Campaign Destination URL"
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.destination_url.value &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://" +
                        formikProps.values.destination_url.value.replace(
                          /^https?\:\/\//i,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />

        {!formikProps.values.campaignID && (
          <div style={{ display: "flex", marginTop: "10px" }}>
            <TextFourteenBlack style={{ color: "#333", opacity: "0.8" }}>
              NOTE: Please add conversion string "?conv=%conversionid%&user=
              &#123;&#123;user.key&#125;&#125;&site=&#123;&#123;site.id&#125;"
              at the end of the destination url
            </TextFourteenBlack>
          </div>
        )}
      </Grid>

      <Grid item xs={5}>
        <Label
          title={"Start Date"}
          formikProps={formikProps}
          required={true}
          name={"start_duration.value"}
        />
      </Grid>

      <Grid item xs={5}>
        <Label
          title={"End Date"}
          formikProps={formikProps}
          required={true}
          name={"end_duration.value"}
        />
      </Grid>

      <Grid item xs={5}>
        <DateTimePicker
          inputFormat="dd/MM/yyyy"
          name="start_duration.value"
          formikProps={formikProps}
          value={formikProps.values.start_duration.value}
          disabled={!editState}
          minDate={new Date()}
          inputProps={{ "aria-label": "Without label" }}
          onChange={(newValue) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              start_duration: setValue(prevVal.start_duration),
              startTime: newValue,
            }));

            function setValue(val) {
              val.value = newValue;
              return val;
            }
          }}
          renderInput={(params) => (
            <TextField
              disabled={!editState}
              name="start_duration.value"
              formikProps={formikProps}
              {...params}
            />
          )}
        />
      </Grid>

      <Grid item xs={5}>
        <DateTimePicker
          inputFormat="dd/MM/yyyy"
          name="end_duration.value"
          formikProps={formikProps}
          disabled={!editState}
          value={formikProps.values.end_duration.value}
          minDate={formikProps.values.start_duration.value + 1}
          inputProps={{ "aria-label": "Without label" }}
          onChange={(newValue) => {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              end_duration: setValue(prevVal.end_duration),
              endTime: newValue,
            }));
            function setValue(val) {
              val.value = newValue;
              return val;
            }
          }}
          renderInput={(params) => (
            <TextField
              disabled={!editState}
              name="end_duration.value"
              formikProps={formikProps}
              {...params}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Desired Volume" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="volume.value"
          placeholder="Desired Volume"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Monthly Active User"
          formikProps={formikProps}
          required={true}
          name={"mau.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={!editState}
          name="mau.value"
          formikProps={formikProps}
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Business Category" />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="category.value"
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          disabled={!editState}
          options={categories}
          placeholder="Select Category"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "category.value", "name")
          }
        />
      </Grid>
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Paper>
  );

  function handleChange(arr, { option }, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
      };
    }
  }
}
