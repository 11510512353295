import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { createBrandObject, validationSchema } from "./createBrandObject";
import { setActiveBrand, setBrands } from "../../../redux/slices/memberSlice";

export default function Form({ categories, location, languages }) {
  const axios = useAxios();

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const memberDetails = useSelector((state) => state.memberDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={createBrandObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        const data = new FormData();
        data.append("business_entity_id", memberDetails.businessEntityID);
        data.append("brand_name", formData.brandName);
        data.append("website", formData.website);
        data.append(
          "audience_location",
          JSON.stringify(formData.audienceLocation)
        );
        data.append("brandLogo", formData.brandLogo);
        Boolean(
          data.append("languages", JSON.stringify(formData.audienceLanguages))
        );
        Boolean(
          data.append(
            "social_media_handles",
            JSON.stringify(formData.socialMedia)
          )
        );
        Boolean(data.append("about", formData.about));
        Boolean(data.append("online_store", formData.onlineStore));
        Boolean(data.append("mau", formData.monthlyActiveUsers));
        Boolean(
          data.append("site_category", JSON.stringify(formData.siteCategory))
        );

        axios({
          url: "/user/brand",
          method: "POST",
          data: data,
        })
          .then((res) => {
            if (res.success) {
              dispatch(
                alert({
                  message: "Brand created successfully!",
                  type: "success",
                })
              );
              sessionStorage.setItem("activeBrand", res.data.brand_id);
              localStorage.setItem(
                "dashboardState" + res.data.brand_id,
                "monetization"
              );

              dispatch(setActiveBrand(res.data));
              dispatch(setBrands(res.data));

              navigate(pages.brandDetails.route);

              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form style={{ padding: "20px" }} onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            categories={categories}
            location={location}
            languages={languages}
          />
        </form>
      )}
    </Formik>
  );
}
