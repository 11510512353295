import { Fragment } from "react";
import styled from "styled-components/macro";
import { Grid, Typography, Checkbox, Slider } from "@mui/material";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";

import { Label, Paper } from "../../../components";
import AutocompleteMultiSelect from "../../../components/MultiselectAutoComplete";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

const DpCount = styled(Typography)`
  color: #333;
  font-weight: 400;
  font-size: 13px;
`;

const PercentageSlider = styled(Slider)({
  color: "#fff",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
    background: "#02a0fc",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid #02a0fc",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 50,
    height: 32,
    backgroundColor: "#02a0fc",
  },
});

export default function FormTwo({
  formikProps,
  editState,
  location,
  languages,
  mandatoryFalseCount,
  percentageSliderValue,
  count,
  setPercentageSliderValue,
  oldMandatoryFalseCount,
  setOldMandatoryFalseCount,
}) {
  function handleSliderChange(e) {
    setPercentageSliderValue(e.target.value);

    formikProps.setValues((prev) => ({
      ...prev,
      percentage: { value: e.target.value },
    }));
  }
  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Audience Preferences</MainHeading>
      </Grid>

      <Grid item xs={6}>
        <Label title={"Location"} />
      </Grid>
      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: formikProps.values.audience_location?.mandatory
              ? "red"
              : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled={
            !editState ||
            formikProps.values.audience_location?.value?.length === 0
          }
          name="audience_location.mandatory"
          onChange={handleCheckChange}
          checked={formikProps.values.audience_location?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>
      {!formikProps.values.ID && (
        <Grid item xs={10} padding="0">
          <DpCount>
            {formikProps.values.audience_location?.dpCount} partners
          </DpCount>
        </Grid>
      )}
      <Grid item xs={10}>
        <AutocompleteMultiSelect
          forcePopupIcon={!editState ? false : true}
          name="audience_location.value"
          formikProps={formikProps}
          disabled={!editState}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={location}
          placeholder="Select Location"
          onChange={(_, arr, r, detail) =>
            handleChange(arr, detail, "audience_location.value", "country_code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Language"} />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: formikProps.values.languages?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled={
            !editState || formikProps.values.languages.value.length === 0
          }
          name="languages.mandatory"
          onChange={handleCheckChange}
          checked={formikProps.values.languages?.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      {!formikProps.values.ID && (
        <Grid item xs={10} padding="0">
          <DpCount>{formikProps.values.languages.dpCount} partners</DpCount>
        </Grid>
      )}

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="languages.value"
          formikProps={formikProps}
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={languages}
          placeholder="Select Language"
          onChange={(_, arr, r, detail) =>
            handleChange(arr, detail, "languages.value", "code")
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Non mandatory count"} />
      </Grid>

      <Grid item xs={10} mt={"10"}>
        <PercentageSlider
          step={1}
          marks
          valueLabelDisplay="on"
          value={formikProps.values.percentage.value}
          min={0}
          max={mandatoryFalseCount}
          style={{ color: "#808080" }}
          onChange={handleSliderChange}
          //    onChangeCommitted={(e) => handleDrag(e)}
        />
      </Grid>
    </Paper>
  );

  function handleChange(arr, { option }, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
      // percentage: setPercentage(prevVal.percentage),
    }));

    function setPercentage(percentage) {
      let p = 0;

      for (let key of Object.keys(formikProps.values)) {
        if (
          key === "age" ||
          key === "gender" ||
          key === "arpu" ||
          key === "audience_location" ||
          key === "languages"
        ) {
          if (formikProps.values[key].mandatory) {
            p = p - 1;
          }
        }
      }

      return {
        ...percentage,
        value: 5 - p,
      };
    }

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
        if (values.length === 0) {
          val.mandatory = false;
        } else {
          val.mandatory = true;
        }
      } else {
        values.push(option[displayName]);
        val.mandatory = true;
      }

      return {
        ...val,
        value: values,
      };
    }
  }

  function handleCheckChange(e) {
    const { name } = e.target;
    const objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      percentage: setPercentage(prevVal.percentage),
    }));

    function setPercentage(percentage) {
      let p = percentage.value;

      if (
        mandatoryFalseCount <= percentageSliderValue &&
        Boolean(formikProps.values.ID)
      ) {
        if (p === percentageSliderValue && p === mandatoryFalseCount) {
          count.current = false;
        }

        if (!e.target.checked) {
          setPercentageSliderValue(mandatoryFalseCount);
          p = mandatoryFalseCount;
        } else {
          p = p - 1;
        }
      }

      if (
        mandatoryFalseCount < oldMandatoryFalseCount &&
        oldMandatoryFalseCount == percentageSliderValue &&
        !Boolean(formikProps.values.ID)
      ) {
        setPercentageSliderValue(mandatoryFalseCount);
        p = mandatoryFalseCount;
      }
      setOldMandatoryFalseCount(mandatoryFalseCount);

      return { ...percentage, value: p };
    }

    function setValues(val) {
      return { ...val, mandatory: e.target.checked };
    }
  }
}
