import * as React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Paper, InputBase, IconButton, Grid } from "@mui/material";

export default function SortBy() {
  return (
    <Grid item>
      <Paper
        component="form"
        elevation={0}
        sx={{
          p: "15px 18px",
          display: "flex",
          alignItems: "center",
          width: "18vw",
          height: "50px",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, color: "rgba(128, 128, 128, 0.7)" }}
          placeholder="Sort By"
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <KeyboardArrowDown style={{ color: "#231F20" }} />
        </IconButton>
      </Paper>
    </Grid>
  );
}
