import styled from "styled-components/macro";
import { PhotoCamera } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";

import { Paper, Label, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormSixth({ formikProps, editState }) {
  function handleFileChange(event) {
    const file = event.target.files[0];
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      file_name: setFileName(prevVal.file_name),
      previewImage: file,
    }));

    function setFileName(val) {
      val.value = file.name;
      return val;
    }
  }

  return (
    <Paper style={{ marginTop: "25px" }}>
      <Grid item xs={12}>
        <MainHeading>Preview Image</MainHeading>
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Preview Image"
          formikProps={formikProps}
          required={true}
          name={"previewImage.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          required
          disabled={!editState}
          name="file_name.value"
          formikProps={formikProps}
          placeholder="Select Preview Image"
          inputProps={{
            "aria-label": "Without label",
            IconComponent: () => editState && null,
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                disabled={!editState}
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  onChange={handleFileChange}
                  hidden
                  accept="image/*"
                  type="file"
                />
                <PhotoCamera />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Paper>
  );
}
