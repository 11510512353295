import { useState } from "react";
import {
  Button,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { HighlightOff } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { Label } from "../../../components";
import { useAlert } from "../../../hooks";
import AutocompleteMultiSelect from "./MultiAutoComplete";

export default function Form({
  adTagData,
  formikProps,
  adSize,
  setShowDialog,
}) {
  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [check, setCheck] = useState(true);

  let adTagScript = check
    ? `<script type="text/javascript" src="https://static.adzerk.net/ados.js" >
      </script>
       <script type = "text/javascript" >
       function digestMessage(message) {
        var msgUint8 = new TextEncoder().encode(message);
        return crypto.subtle.digest("SHA-256", msgUint8).
        then(function(hashBuffer) {
            return Array.from(new Uint8Array(hashBuffer))
            .map(function(b) { return b.toString(16).padStart(2, "0")}).join("");
        })
    }
var ados = ados || {};
digestMessage(emailOrPhone).then(function(hashedUserId) {
    ados.run = ados.run || [];
    ados.run.push(function() {
    ados_add_placement(${adTagData.values.publisher.networkId}, ${adTagData.values.publisher.siteId}, "mox${adTagData.values.publisher.zoneId}", [${formikProps.values.divID.value}] )
      .setZone(${adTagData.values.publisher.zoneId});
            .setProperties({});
        ados_setUser(hashedUserId);
        ados_setDomain('promos.moxplatform.com')
        ados_load();
    });
}); </script>`
    : `<script type="text/javascript" src="https://static.adzerk.net/ados.js" >
       </script> 
       <script type = "text/javascript" >
        var ados = ados || {};
        ados.run = ados.run || [];
        ados.run.push(function() {
         ados_add_placement(${adTagData.values.publisher.networkId}, ${adTagData.values.publisher.siteId},"mox${adTagData.values.publisher.zoneId}",[${formikProps.values.divID.value}])
          .setZone(${adTagData.values.publisher.zoneId});
          .setProperties({});
         ados_setUser('<userEmailHash>');
    ados_setDomain('promos.moxplatform.com')
    ados_load();
}); </script>`;

  return (
    <DialogContent>
      <Grid textAlign={"end"}>
        <IconButton padding="0px" onClick={() => setShowDialog(false)}>
          <HighlightOff />
        </IconButton>
      </Grid>

      <Grid item paddingBottom={3}>
        <Typography variant="h4">AD-Tag</Typography>
      </Grid>

      <Grid pb={3}>
        <Grid item xs={12}>
          <Label title="AD Size" name="adSize" />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteMultiSelect
            size="small"
            name="divID.value"
            formikProps={formikProps}
            defaults={{
              primaryKey: "id",
              displayFirstLabel: "name",
              displaySecLabel: "width",
              displayThirdLabel: "height",
            }}
            options={adSize}
            placeholder="Select AD-Tag ID"
            onChange={(_, arr, r, option) =>
              handleChange(arr, option, "divID.value", "id")
            }
          />
        </Grid>
      </Grid>

      <Grid
        p={5}
        border={"1px solid #00000066"}
        sx={{ height: "325px", overflow: "auto" }}
      >
        <Grid item display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h4">Add to the html head :</Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(adTagScript);
              dispatch(
                alert({
                  message: "Copied AD-Tag Successfully!",
                  type: "success",
                })
              );
            }}
          >
            <FileCopyIcon fontSize="small" sx={{ mr: 2 }}></FileCopyIcon>
            Copy AD-Tag
          </Button>
        </Grid>

        <Typography style={{ whiteSpace: "pre" }}>{adTagScript}</Typography>
      </Grid>

      <Grid p={5} border={"1px solid #00000066"} mt={2}>
        <Grid item display={"flex"} justifyContent={"space-between"} mb={3}>
          <Typography variant="h5">Add to the HTML body :</Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(
                `<div id="mox${adTagData.values.publisher.zoneId}"></div>`
              );
              dispatch(
                alert({
                  message: "Copied HTML-Tag Successfully!",
                  type: "success",
                })
              );
            }}
          >
            <FileCopyIcon fontSize="small" sx={{ mr: 2 }}></FileCopyIcon>
            Copy HTML-Tag
          </Button>
        </Grid>

        <Typography style={{ whiteSpace: "pre-line" }}>
          {`<div id="mox${adTagData.values.publisher.zoneId}"></div>`}
        </Typography>
      </Grid>

      <Grid item display={"flex"} alignItems={"center"}>
        <Grid item>
          <Checkbox checked={check} onChange={() => setCheck(!check)} />
        </Grid>

        <Grid item>
          <Typography variant="h4">Hash Mail</Typography>
        </Grid>
      </Grid>
    </DialogContent>
  );

  function handleChange(arr, { option }, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => {
      return {
        ...prevVal,
        [objectName]: setValuesOfDropDown(prevVal[objectName]),
      };
    });

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
      };
    }
  }
}
