import React from "react";
import styled from "styled-components/macro";
import { HighlightOff } from "@mui/icons-material";
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

import { Label } from "../../../../components";
import { colors } from "../../../css components/Style";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function ConnectionDetailsDilog({ details, setDetails }) {
  const handleClose = () => {
    if (details.component === "connection") {
      setDetails((prev) => ({
        state: false,
        data: {},
      }));
    } else {
      setDetails((prev) => ({ ...prev, state: false }));
    }
  };

  return (
    <Card
      open={details?.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
          padding: "20px 24px",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <MainHeading>Connection Statistics</MainHeading>
          </Grid>

          <Grid item xs={4} textAlign="end">
            <IconButton padding="0px" onClick={handleClose}>
              <HighlightOff />
            </IconButton>
          </Grid>

          <Grid item xs={6}>
            <Label title={"Expected Count"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details?.data?.expectedCount || "N.A."}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"PID Count"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details?.data?.usersCount || "N.A."}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"PID Status"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details.data?.pidStatus || "N.A"}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"User DB Count"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details.data?.userDbCount || "N.A."}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"User DB Status"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details.data?.dbStatus || "N.A."}
              color={colors.info}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Card>
  );
}
