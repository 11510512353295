import styled from "styled-components/macro";
import { TbExternalLink } from "react-icons/tb";
import { PhotoCamera } from "@mui/icons-material";
import { Grid, Typography, IconButton, Avatar } from "@mui/material";

import {
  Label,
  Paper,
  TextField,
  AutocompleteMultiSelect,
} from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const ImageAvatar = styled(Avatar)`
  border: 1px solid #999999;
  height: 120px;
  width: 120px;
  border-radius: 50%;
`;

export default function FormOne({
  component = "",
  formikProps,
  editState,
  categories,
  location,
  languages,
}) {
  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        fileName: file.name,
        brandAvatar: file,
        avatar: URL.createObjectURL(file),
      }));
    }
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Your Brand Profile</MainHeading>
      </Grid>

      <Grid item xs={12} textAlign="center" display="flex">
        <ImageAvatar src={formikProps.values.avatar} />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Brand Logo"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          required
          disabled={component === "" && !editState}
          name="fileName"
          formikProps={formikProps}
          placeholder="Select Brand Logo"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                disabled={component === "" && !editState}
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  onChange={handleFileChange}
                  hidden
                  accept="image/*"
                  type="file"
                />
                <PhotoCamera />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title={"Brand Name"}
          formikProps={formikProps}
          required={true}
          name={"brandName"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          placeholder="Brand Name"
          name="brandName"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="About"
          formikProps={formikProps}
          required={true}
          name={"about"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          placeholder="About"
          name="about"
          rows={5}
          multiline
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Online Store"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="onlineStore"
          placeholder="Online Store"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.onlineStore && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://" +
                      formikProps.values.onlineStore.replace(
                        /^https?\:\/\//i,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Website"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="website"
          placeholder="Website"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.website && !editState && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://" +
                      formikProps.values.website.replace(/^https?\:\/\//i, "")
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Monthly Active Users" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="monthlyActiveUsers"
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Business Category"
          formikProps={formikProps}
          required={true}
          name={"siteCategory"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="siteCategory"
          disabled={component === "" && !editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={categories}
          placeholder="Select Category"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "siteCategory", "name")
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Label
          title={"Location"}
          formikProps={formikProps}
          required={true}
          name={"audienceLocation"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audienceLocation"
          disabled={component === "" && !editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={location}
          placeholder="Select Location"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLocation", "country_code")
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Label
          title={"Language"}
          formikProps={formikProps}
          required={true}
          name={"audienceLanguages"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audienceLanguages"
          disabled={component === "" && !editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={languages}
          placeholder="Select Language"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLanguages", "code")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Facebook"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="socialMedia.facebook"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.facebook &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://facebook.com/" +
                        formikProps.values.socialMedia.facebook.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Instagram"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.instagram &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://instagram.com/" +
                        formikProps.values.socialMedia.instagram.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Twitter"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.twitter &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://twitter.com/" +
                        formikProps.values.socialMedia.twitter.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Whatsapp"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={component === "" && !editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.whatsapp &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://wa.me/" + formikProps.values.socialMedia.whatsapp
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>
    </Paper>
  );

  function handleChange(arr, { option }, name, displayName) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [name]: setValuesOfDropDown(prevVal[name]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val];
      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(option[displayName]);
      }

      return values;
    }
  }

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
