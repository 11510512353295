import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";

import pages from "../../constants/pages";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Search from "./onboarding list components/Search";
import { PrimaryButton } from "../css components/Button";
import { NoListTypography } from "../css components/Style";
import BrandList from "./onboarding list components/BrandList";
import Categorize from "./onboarding list components/Categorize";

export default function OnBoardedConnectionList() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [dpList, setDpList] = useState([]);
  const [brands, setBrands] = useState("Brands");

  useEffect(() => {
    loader.start();
    axios({
      url: "/user/getAllDataPartner",
    }).then((response) => {
      if (response.success) {
        setDpList(response.data);
        loader.apiComplete();
      }
    });
  }, [loader, axios]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"All Brands"} heading={"Onboarding"} />
        </Grid>

        <Categorize brands={brands} setBrands={setBrands} />

        <Search />

        <Grid item xs={3} />

        <Grid item xs={3} textAlign={"end"}>
          <PrimaryButton
            variant="contained"
            size="large"
            onClick={() => navigate(pages.dpOnBoarding.route)}
          >
            New Onboarding
          </PrimaryButton>
        </Grid>

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {dpList.length > 0 ? (
          <BrandList dpList={dpList} />
        ) : (
          <NoListTypography>No new brands!</NoListTypography>
        )}
      </Loader>
    </Fragment>
  );
}
