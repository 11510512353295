import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import Sort from "../Sort";
import { Paper } from "../../../components";
import Brand from "../../../assets/BrandLogo.png";
import { HeadingEighteen, TextFourteenBlack } from "../../css components/Style";

const RevenuCaption = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #4339f2;
`;

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
`;

const ImageDiv = styled.div`
  height: 70px;
  width: 70px;
`;

export default function List() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Sort />
      </Grid>
      <Grid item xs={8}>
        <Paper>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <ImageDiv>
              <img
                style={{
                  height: "inherit",
                  width: "inherit",
                  borderRadius: "6px",
                }}
                src={Brand}
                alt="!"
              />
            </ImageDiv>
            <Typography
              component="span"
              style={{
                marginLeft: "8px",
                fontSize: "20px",
                fontWeight: "400",
              }}
            >
              Flickstree
            </Typography>
          </Grid>

          <Grid item xs>
            <Grid container spacing={3}>
              <Grid item mr="15px" />
              <Grid item>
                <TextFourteenBlack>Conversion Type</TextFourteenBlack>
                <HeadingEighteen>18%</HeadingEighteen>
              </Grid>
              <Grid item mr="15px" />

              <Grid item>
                <TextFourteenBlack>Desired Volume</TextFourteenBlack>
                <HeadingEighteen>18%</HeadingEighteen>
              </Grid>

              <Grid item mr="15px" />
              <Grid item>
                <TextFourteenBlack>Total Revenue</TextFourteenBlack>
                <HeadingEighteen>$160,56M.88</HeadingEighteen>
              </Grid>

              <Grid item mr="15px" />

              <Grid item>
                <TextFourteenBlack>Total Payout </TextFourteenBlack>
                <RevenuCaption>$52,39M.99</RevenuCaption>
              </Grid>

              <Grid item xs />

              <Grid item alignSelf={"center"}>
                <Link style={{ cursor: "pointer" }}>View Partner</Link>
              </Grid>
              <Grid item xs />

              <Grid item alignSelf={"center"}>
                <Link style={{ cursor: "pointer" }}>All Billings</Link>
              </Grid>
              <Grid item xs />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
