import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

import { upperCase } from "change-case-all";
import pages from "../../../constants/pages";
import { Paper, TextAvatar } from "../../../components";
import {
  ActiveTextEighteen,
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { toInternationalFormat } from "../../../utils";

const Link = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

export default function List({ audiences }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {audiences?.map((audience) => (
        <Grid item xs={12}>
          <Paper>
            <Grid item alignSelf="center">
              <TextAvatar
                size={{ height: "50px", width: "50px" }}
                userName={audience?.audience_group}
              />
            </Grid>

            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <TextFourteenBlack>Audience Group</TextFourteenBlack>

                  <HeadingEighteen>{audience.audience_group}</HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack>Age Range</TextFourteenBlack>
                  <HeadingEighteen>
                    {audience.age?.min
                      ? audience.age?.min + "-" + audience.age?.max
                      : "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack>Gender</TextFourteenBlack>
                  <HeadingEighteen>
                    {audience.gender?.length > 0
                      ? audience.gender.map((cat) => cat).join(", ")
                      : "-"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={1}>
                  <TextFourteenBlack>ARPU</TextFourteenBlack>
                  <HeadingEighteen>
                    {audience.arpu?.min
                      ? toInternationalFormat(audience.arpu?.min) +
                        "-" +
                        toInternationalFormat(audience.arpu?.max)
                      : "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Location</TextFourteenBlack>

                  <ActiveTextEighteen color={colors.success}>
                    {audience.audience_location?.length > 0
                      ? audience.audience_location
                          .map((loc) => upperCase(loc.substr(0, 2)))
                          .join(", ")
                      : "-"}
                  </ActiveTextEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Language</TextFourteenBlack>
                  <ActiveTextEighteen color={"black"}>
                    {audience.languages?.length > 0
                      ? audience.languages
                          .map((loc) => upperCase(loc.substr(0, 2)))
                          .join(", ")
                      : "-"}
                  </ActiveTextEighteen>
                </Grid>

                <Grid
                  item
                  xs={1}
                  textAlign={"end"}
                  alignSelf={"end"}
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Link
                    style={{ cursor: "pointer", paddingRight: "0px" }}
                    onClick={() =>
                      navigate(
                        pages.audienceDetails.route + audience.audience_id
                      )
                    }
                  >
                    View
                  </Link>
                  <ArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
