import { Grid } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FieldName from "./FieldName";
import { PageHeader } from "../../../components";
import Buttons from "../../../components/Buttons";
import BASE_URL from "../../../constants/baseURL";
import { PrimaryLoadingButton } from "../../css components/Button";
import { useAxios, useComponent } from "../../../hooks";

export default function FormDisplay({
  brands,
  fields,
  editState,
  formikProps,
  setEditState,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const { alert } = useComponent();
  const [loading, setLoading] = useState(false);

  function handleConnection() {
    setLoading(true);
    axios({
      url: "/user/testConnectionDetails",
      method: "POST",
      data: {
        connection_url: formikProps.values.connectionUrl,
        port: formikProps.values.port,
        token: formikProps.values.token,
      },
      disableRedirect: true,
    }).then((res) => {
      if (res.success) {
        formikProps.setValues((prev) => ({
          ...prev,
          connection:
            res.data[0].connection_establishment === "success" ? true : false,
          fieldNames: res.data[1].column_names,
        }));
        dispatch(
          alert.success(res.data[0]?.connection_establishment || "Success")
        );
      } else {
        dispatch(
          alert.error(res.data[0]?.connection_establishment || "Failed")
        );
      }

      setLoading(false);
    });
  }
  return (
    <Fragment>
      <Grid item xs={12} display="flex">
        <PageHeader
          heading={"DP Onboarding"}
          twoText={!formikProps.values.connectionID && true}
          pageName={formikProps.values.brandDetails?.brand_name}
          firstText={"Welcome. "}
          secondText={" Set your promotional journey."}
          iconImage={
            formikProps.values.connectionID &&
            BASE_URL.mox_api.url + formikProps.values.brandDetails?.logo
          }
          fontSize="25px"
          buttons={[
            <Buttons
              ID={formikProps.values.ID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
            />,
            !formikProps.values.noDocker && (
              <PrimaryLoadingButton
                loading={loading}
                variant="contained"
                onClick={handleConnection}
                disabled={
                  !formikProps.values.connectionUrl && !formikProps.values.port
                }
              >
                Test Connection
              </PrimaryLoadingButton>
            ),
          ]}
        />
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            formikProps={formikProps}
            brands={brands}
            editState={editState}
          />

          {formikProps.values.connection && !formikProps.values.noDocker && (
            <FieldName formikProps={formikProps} />
          )}
        </Grid>

        <Grid item xs={6}>
          {fields.length > 0 && !formikProps.values.noDocker && (
            <FormTwo
              formikProps={formikProps}
              fields={fields}
              editState={editState}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
