import React from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Paper } from "../../../components";

const Heading = styled(Typography)`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 22px;
  margin-top: 8px;
  color: #fff;
`;

const SubHeading = styled(Typography)`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 67.02px;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 8px;
`;

const Text = styled(Typography)`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  color: #fff;
`;

const ContentItem = styled.div`
  margin: 10px;
`;

const Card = styled(Paper)`
  background: #4339f2;
  border-radius: 10px;
  height: 260px;
  width: 290px;
`;
const RevenueCard = styled(Paper)`
  background: #02a0fc;
  border-radius: 10px;
  height: 260px;
  width: 290px;
`;
const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Payouts = styled(Paper)`
  background: #34b53a;
  border-radius: 10px;
  height: 260px;
  width: 290px;
`;

export default function CampaignMetrics() {
  return (
    <React.Fragment>
      <Paper>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "7px",
            paddingLeft: " 52px",
          }}
        >
          <MainHeading>Campaign Metrics</MainHeading>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{ justifyContent: "center", paddingLeft: " 52px" }}
        >
          <Grid item xs={6} sm={6}>
            <Card>
              <ContentItem>
                <ContentItem>
                  <Heading>Conversion Rates</Heading>
                  <SubHeading>$3%</SubHeading>
                  <Text>+28% prev mth</Text>
                </ContentItem>
              </ContentItem>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6}>
            <Payouts>
              <ContentItem>
                <Heading>Click Through Rates</Heading>
                <SubHeading>18%</SubHeading>
                <Text>+5% prev mth</Text>
              </ContentItem>
            </Payouts>
          </Grid>

          <Grid item xs={6} sm={6}>
            <RevenueCard>
              <ContentItem>
                <Heading>Total Transactions</Heading>
                <SubHeading>570</SubHeading>
                <Text>+28% prev mth</Text>
              </ContentItem>
            </RevenueCard>
          </Grid>

          <Grid item xs={6} sm={6}>
            <Payouts>
              <ContentItem>
                <Heading>Total Revenue</Heading>
                <SubHeading>$2.8M</SubHeading>
                <Text>+5% prev mth</Text>
              </ContentItem>
            </Payouts>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
